export const columns = [
  {
    accessor: 'tipoCosto',
    Header: 'Ítem'
  },
  {
    accessor: 'descripcionCorta',
    Header: 'Descripción'
  },
  {
    accessor: 'valorFormat',
    Header: 'Valor'
  },
  {
    accessor: 'fechaCostoFormat',
    Header: 'Fecha Costo'
  },
  {
    accessor: 'accion',
    Header: 'Acciones'
  }
];

export const columnsCliente = [
  {
    accessor: 'tipoCosto',
    Header: 'Ítem'
  },
  {
    accessor: 'descripcionCorta',
    Header: 'Descripción'
  },
  {
    accessor: 'valorFormat',
    Header: 'Valor'
  },
  {
    accessor: 'fechaCostoFormat',
    Header: 'Fecha Costo'
  },
];

  export const columns2 = [
    {
      accessor: 'tipoCosto',
      Header: 'Ítem'
    },
    {
      accessor: 'descripcionCorta',
      Header: 'Descripción'
    },
    {
      accessor: 'valorFormat',
      Header: 'Valor'
    },
    {
      accessor: 'fechaCostoFormat',
      Header: 'Fecha Costo'
    }
    ];