import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Modal, CloseButton, Button, Spinner } from 'react-bootstrap'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import axios from 'utils/axios';

import useAuth from 'hooks/useAuth';
import { verifyAccess } from 'helpers';

import moment from 'moment';
import { columnsBitacora, columnsEquipo } from '../data'
import { CreateEquipo } from './createEquipo';
import { EditEquipo } from './editEquipo';
import { DisabledEquipo } from './disabledEquipo';
import { BitacoraEquipo } from './bitacoraEquipo';
import {getSixWords, toastNotification, verifyResponseUnauthorizedFetch} from "helpers/utils";
import useLoadingContext from 'hooks/useLoadingContext';

const EquipoAdmin = () => {
  const navigate = useNavigate();

  const { user } = useAuth();
  const { setIsLoading } = useLoadingContext();
  const [ readyContent, setReadyContent ] = useState(false);
  const [ readyContentBitacora, setReadyContentBitacora ] = useState(false);

  const [ equipos, setEquipos] = useState([]);
  const [ equipoSelect, setEquipoSelect] = useState([]);
  const [ deleteEquipo, setDeleteEquipo] = useState();

  const [ allSizesEquipos, setllSizesEquipos ] = useState([]);
  const [ bitacoraIndividual, setBitacoraIndividual] = useState([]);
  const [ equiposToTable, setEquiposToTable ] = useState([]);

  // MODAL
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showBitacoraGeneralModal, setShowBitacoraGeneralModal] = useState(false);
  const [showBitacoraIndividualModal, setShowBitacoraIndividualModal] = useState(false);
  const [showDisabledModal, setShowDisabledModal] = useState(false);

  const [ elementPerPage, setElementPerPage ] = useState(10)

  const startResetApp = async () => {
    getAllequipos();
    getAllTamañosEquipos();
  }

  const getAllequipos = async () => {
    setReadyContent(false);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/equipo/getAllEquipments`;
    axios.get(url).then((response) => {
      if( verifyResponseUnauthorizedFetch(response, 1) ) return;
      if( response.status !== 200 ) return;
      const list = response.data.data;
      const equipos = list.map((element) => {
        element.size = element.tamanoEquipo.descripcion
        return element;
      })
      setReadyContent(true);
      setEquipos(equipos);
    }) .catch((err) => {
      console.error('Error al intentar obtener los equipos');
      setReadyContent(true);
    })
  }

  useEffect( () => {
    if( equipos.length < 1 ) return;
    const equiposToTable = equipos.map( equipo => {
      let toReturn = {};
      toReturn.id = equipo.id;
      toReturn.model = equipo.model;
      toReturn.name = equipo.name;
      toReturn.rpm = equipo.rpm;
      toReturn.serialNumber = equipo.serialNumber;
      toReturn.size = equipo.size;
      toReturn.type = equipo.type;
      toReturn.codeId = equipo.codeId
      toReturn.brand = equipo.brand;
      toReturn.area = equipo.area;
      toReturn.description = ( equipo.description.length > 0 ) ? getSixWords(equipo.description) : ''
      toReturn.acciones = [
        <IconButton
          title="Ver Detalle"
          variant="white"
          size="sm"
          icon="eye"
          style={{ boxShadow: "none", width: "25%" }}
          onClick={() => navigate(`/administrador/equipo/${equipo.id}`)}
        ></IconButton>,
        <IconButton
          title="Editar"
          variant="white"
          size="sm"
          icon="edit"
          style={{ boxShadow: "none", width: "25%" }}
          onClick={() => {
            setShowEditModal(true)
            setEquipoSelect(equipo)
          }}
        ></IconButton>,
        <IconButton
        title="Deshabilitar"
        variant="white"
        size="sm"
        icon="trash-alt"
        style={{ boxShadow: "none", width: "25%" }}
        onClick={() => {
          setShowDeleteModal(true)
          setDeleteEquipo(equipo.id)
        }}
        ></IconButton>,
        <IconButton
        title="Ver Bitácora"
        variant="white"
        size="sm"
        icon="book"
        style={{ boxShadow: "none", width: "25%" }}
        onClick={() => {
          setBitacoraIndividual([]);
          setShowBitacoraIndividualModal(true)
          getBitacoraIndividual(equipo.id)
        }}
        ></IconButton>
      ]
      return toReturn;
    } )
    setEquiposToTable(equiposToTable);
  }, [equipos] );

  const getAllTamañosEquipos = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/sizeEquipo/getSizesEnabledAndDisabled`;
    try {
      const { data } = await axios.get(url);
      if( data === undefined || !data ) return;
      if( data.statusCode == 200 ) setllSizesEquipos(data.data);
    } catch (error) {
      console.error("Error al intentar obtener todos los tamaños de equipos");
    }
  }

  const getBitacoraIndividual = async (id) => {
    setReadyContentBitacora(false);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/equipo/getBitacoraById/${id}`;
    axios.get(url).then((response) => {
      if( verifyResponseUnauthorizedFetch(response, 1) ) return;
      if(response.data.statusCode === 200){
        const list =  response.data.data;
        list.forEach(element => {
          element.fecha = moment(element.fecha_transaccion).format('DD-MM-YYYY');
          element.hora = moment(element.fecha_transaccion).format('hh:mm:ss');
        })
        setBitacoraIndividual(list);
        setReadyContentBitacora(true);
      }
    });
  }

  //? INICIANDO LA APP
  useEffect(() => {
    const hasAccess = verifyAccess(user, 'profileEquiposAdmin', '/administrador/dashboard')
    if( !hasAccess ) navigate("/administrador/dashboard");
    if( hasAccess ) startResetApp();
  }, [])

  const handleChange = (data) => {
    startResetApp()
  }

  // DESHABILITAR
  const handleDelete = async () => {
    setIsLoading(true);
    await axios.delete(`${process.env.REACT_APP_ADMIN_SERVICE}/equipo/${deleteEquipo}/${user.id}`)
    .then((response) => {
      setIsLoading(false);
      if( verifyResponseUnauthorizedFetch(response, 4) ) return;
      setReadyContent(false);
      startResetApp()
      setShowDeleteModal(false)
      toastNotification('success', 'Equipo deshabilitado correctamente')
      }
    )
    .catch((err) => {
      setIsLoading(false);
      toastNotification('error', 'Ocurrió un error al deshabilitar el equipo')
      setShowDeleteModal(false);
      setReadyContent(true);
    })
  };

  return (
    <>
      <div>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="justify-content-between">
            <Col lg={6} className="ps-lg-4 my-5text-lg-start">
              <h3 className="text-primary">Equipos</h3>
            </Col>
          </Row>

          { !readyContent ? 
          <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
          : (
            <Card className="h-md-100">
              <Card.Body>

              <AdvanceTableWrapper
              columns={columnsEquipo}
              data={equiposToTable}
              sortable
              pagination
              perPage={10}
            >

              <Row className="mb-3">
                <Col xs={12} lg={4} className='mx-auto mb-2'>
                  <div className='text-center mb-sm-2'>
                    <IconButton
                      variant="falcon-success"
                      size="sm"
                      icon="plus"
                      transform="shrink-3"
                      className="me-2"
                      onClick={() => setShowCreateModal(true)}
                    >
                      Nuevo Equipo
                    </IconButton>
                  </div>
                </Col>
                <Col xs={12} lg={4} className='mx-auto mb-2'>
                  <div className='text-center mb-sm-2'>
                    <IconButton
                      variant="falcon-primary"
                      size="sm"
                      icon="book"
                      transform="shrink-3"
                      className="me-2"
                      onClick={() => {
                        setShowBitacoraGeneralModal(true)
                      }}
                    >
                      Bitácora General
                    </IconButton>
                  </div>
                </Col>
                <Col xs={12} lg={4} className='mx-auto mb-2'>
                  <div className='text-center mb-sm-2'>
                    <IconButton
                      variant="falcon-warning"
                      size="sm"
                      icon="trash"
                      transform="shrink-3"
                      className="me-2"
                      onClick={() => {
                        setShowDisabledModal(true)
                      }}
                    >
                      Deshabilitados
                    </IconButton>
                  </div>
                </Col>
              </Row>
              
              <AdvanceTable
                title='Equipos'
                subtitle='Tabla de Equipos'
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={equipos.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>

              </Card.Body>
            </Card>
          )}
        </Card.Body>
      </div>
      {/* MODAL CREAR */}
      <div>
        <Modal
            size='xl'
            show={showCreateModal}
            centered
            onHide={() => setShowCreateModal(false)}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Crear Equipo
              </Modal.Title>
              <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShowCreateModal(false)}
              />
            </Modal.Header>
            <Modal.Body className="p-0">
              <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                    <CreateEquipo handleChange={handleChange} setShowCreateModal={setShowCreateModal} allSizesEquipos={allSizesEquipos}/>
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
      </div>
      {/* MODAL EDITAR CARGO */}
      <div>
        <Modal
          size='xl'
          show={showEditModal}
          centered
          onHide={() => setShowEditModal(false)}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Editar Equipo
            </Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setShowEditModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-0">
            <Card>
              <Card.Body className="fs--1 fw-normal p-4">
                <EditEquipo data={equipoSelect} handleChange={handleChange} setShowEditModal={setShowEditModal} allSizesEquipos={allSizesEquipos}/>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
      {/* MODAL DESHABILITAR */}
      <div>
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Deshabilitar Equipo</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere deshabilitar el Equipo?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancelar
            </Button>
            <Button variant="danger" 
             onClick={handleDelete}
            >
              Deshabilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* MODAL BITACORA INDIVIDUAL */}
      <div>
        <Modal
          size='xl'
          show={showBitacoraIndividualModal}
          onHide={() => setShowBitacoraIndividualModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Bitácora Individual</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { !readyContentBitacora ? 
            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
              <>
                { !bitacoraIndividual.length > 0 ? (
                  <h6>No hay historial en la Bitácora</h6>
                ) :
                (
                  <div>
                    <AdvanceTableWrapper
                        columns={columnsBitacora}
                        data={bitacoraIndividual}
                        sortable
                        pagination
                        perPage={10}
                      >            
                        <AdvanceTable
                          title='Bítacora de Equipos'
                          subtitle='Tabla Bítacora de Equipos'
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle"
                          rowClassName="align-middle white-space-nowrap"
                          tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                          }}
                        />
                        <div className="mt-3">
                          <AdvanceTableFooter
                            rowCount={bitacoraIndividual.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                          />
                        </div>
                      </AdvanceTableWrapper>
                    </div>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => setShowBitacoraIndividualModal(false)}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* MODAL BITACORA GENERAL */}
      <div>
        <Modal
          size='xl'
          show={showBitacoraGeneralModal}
          onHide={() => setShowBitacoraGeneralModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Bitácora General</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BitacoraEquipo />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => setShowBitacoraGeneralModal(false)}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* MODAL DISABLED */}
      <div>
        <Modal
          size='xl'
          show={showDisabledModal}
          onHide={() => setShowDisabledModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Equipos Deshabilitados</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DisabledEquipo startResetApp={startResetApp} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => {
                setShowDisabledModal(false)
                startResetApp()
              }}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default EquipoAdmin