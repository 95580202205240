import React, { useState, useEffect } from 'react'
import useAuth from 'hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { verifyAccess } from 'helpers';
import { Card, Row, Col, Modal, CloseButton, Button, Spinner } from 'react-bootstrap'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import axios from 'utils/axios';
import moment from 'moment';
import { columnsBitacora, columnsClasificacion } from '../data'
import { CreateClasificacion } from './createClasificacion';
import { EditClasificacion } from './editClasificacion';
import { DisabledClasificacion } from './disabledClasificacion';
import { BitacoraClasificacion } from './bitacoraClasificacion';
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useLoadingContext from 'hooks/useLoadingContext';

const ClasificacionAdmin = () => {
  const navigate = useNavigate();

  const {user} = useAuth();
  const { setIsLoading } = useLoadingContext();
  const [ readyContent, setReadyContent ] = useState(false);
  const [ readyContentBitacora, setReadyContentBitacora ] = useState(false);

  const [ clasificaciones, setClasificaciones] = useState([]);
  const [ clasificacionSelect, setClasificacionSelect] = useState([]);
  const [ deleteClasificacion, setDeleteClasificacion] = useState();

  const [ bitacoraIndividual, setBitacoraIndividual] = useState([]);
  // MODAL
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showBitacoraGeneralModal, setShowBitacoraGeneralModal] = useState(false);
  const [showBitacoraIndividualModal, setShowBitacoraIndividualModal] = useState(false);
  const [showDisabledModal, setShowDisabledModal] = useState(false);

  const startResetApp = async () => {
    getAllClasificaciones();
  }

  const getAllClasificaciones = async () => {
    setReadyContent(false)
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/clasificacion/getAllClasifications`;
    axios.get(url).then((response) => {
      if( verifyResponseUnauthorizedFetch(response, 1) ) return;
      if( response.status !== 200 ) return;
      const list = response.data.data
      list.forEach((element) => {
        element.acciones = [
          <IconButton
            title="Editar"
            variant="white"
            size="sm"
            icon="edit"
            style={{ boxShadow: "none", width: "33%" }}
            onClick={() => {
              setShowEditModal(true)
              setClasificacionSelect(element)
            }}
          ></IconButton>,
          <IconButton
          title="Deshabilitar"
          variant="white"
          size="sm"
          icon="trash-alt"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setShowDeleteModal(true)
            setDeleteClasificacion(element.id)
          }}
          ></IconButton>,
          <IconButton
          title="Ver Bitácora"
          variant="white"
          size="sm"
          icon="book"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setBitacoraIndividual([]);
            setShowBitacoraIndividualModal(true)
            getBitacoraIndividual(element.id)
          }}
          ></IconButton>
        ]
      })
      setReadyContent(true)
      setClasificaciones(list);
    }).catch(error => {
      toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
      setReadyContent(true)
    })
  }

  const getBitacoraIndividual = async (id) => {
    setReadyContentBitacora(false);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/clasificacion/getBitacoraById/${id}`;
    axios.get(url).then((response) => {
      if( verifyResponseUnauthorizedFetch(response, 1) ) return;
      if(response.data.statusCode === 200){
        const list =  response.data.data;
        list.forEach(element => {
          element.fecha = moment(element.fecha_transaccion).format('DD-MM-YYYY');
          element.hora = moment(element.fecha_transaccion).format('hh:mm:ss');
        })
        setBitacoraIndividual(list);
        setReadyContentBitacora(true);
      }
    });
  }

  //? INICIANDO LA APP
  useEffect(() => {
    const hasAccess = verifyAccess(user, 'profileClasificacionAdmin', '/administrador/dashboard')
    if( !hasAccess ) navigate("/administrador/dashboard");
    if( hasAccess ) startResetApp();
  }, [])

  const handleChange = (data) => {
    startResetApp()
  }

    // DESHABILITAR CLASIFICACION
    const handleDelete = async () => {
      setIsLoading(true);
      await axios.delete(`${process.env.REACT_APP_ADMIN_SERVICE}/clasificacion/${deleteClasificacion}/${user.id}`)
      .then((response) => {
        setIsLoading(false);
        if( verifyResponseUnauthorizedFetch(response, 4) ) return;
        setShowDeleteModal(false)
        toastNotification('success', 'Clasificación deshabilitada correctamente')
        startResetApp()
      }
      )
      .catch((err) => {
        setIsLoading(false);
        toastNotification('error', 'Ocurrió un error al intentar deshabilitar la clasificación')
        setTimeout(() => {
          setShowDeleteModal(false);
        }, 2000);
      })
  };

  return (
    <>
        <div>
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="justify-content-between">
              <Col lg={6} className="ps-lg-4 my-5text-lg-start">
                <h3 className="text-primary">Clasificación</h3>
              </Col>
            </Row>

            { !readyContent ? 
              <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            :(
            <Card className="h-md-100">
              <Card.Body>
              <AdvanceTableWrapper
              columns={columnsClasificacion}
              data={clasificaciones}
              sortable
              pagination
              perPage={10}
            >

              <Row className="mb-3">
                <Col xs={12} lg={4} className='mx-auto mb-2'>
                  <div className='text-center'>
                    <IconButton
                      variant="falcon-success"
                      size="sm"
                      icon="plus"
                      transform="shrink-3"
                      className="me-2"
                      onClick={() => setShowCreateModal(true)}
                    >
                      Nueva Clasificación
                    </IconButton>
                  </div>
                </Col>
                <Col xs={12} lg={4} className='mx-auto mb-2'>
                  <div className='text-center'>
                    <IconButton
                      variant="falcon-primary"
                      size="sm"
                      icon="book"
                      transform="shrink-3"
                      className="me-2"
                      onClick={() => {
                        setShowBitacoraGeneralModal(true)
                      }}
                    >
                      Bitácora General
                    </IconButton>
                  </div>
                </Col>
                <Col xs={12} lg={4} className='mx-auto mb-2'>
                  <div className='text-center'>
                    <IconButton
                      variant="falcon-warning"
                      size="sm"
                      icon="trash"
                      transform="shrink-3"
                      className="me-2"
                      onClick={() => {
                        setShowDisabledModal(true)
                      }}
                    >
                      Deshabilitados
                    </IconButton>
                  </div>
                </Col>
              </Row>
              
              <AdvanceTable
                title='Clasificaciones'
                subtitle='Tabla de Clasificaciones'
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={clasificaciones.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
              </Card.Body>
            </Card>
          ) }
        </Card.Body>
      </div>
      {/* MODAL CREAR CLASIFICACION */}
      <div>
        <Modal
            show={showCreateModal}
            centered
            onHide={() => setShowCreateModal(false)}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Crear Clasificación
              </Modal.Title>
              <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShowCreateModal(false)}
              />
            </Modal.Header>
            <Modal.Body className="p-0">
              <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                    <CreateClasificacion handleChange={handleChange} setShowCreateModal={setShowCreateModal} />
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
      </div>
      {/* MODAL EDITAR CLASIFICACION */}
      <div>
        <Modal
          show={showEditModal}
          centered
          onHide={() => setShowEditModal(false)}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Editar Clasificación
            </Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setShowEditModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-0">
            <Card>
              <Card.Body className="fs--1 fw-normal p-4">
                <EditClasificacion data={clasificacionSelect} handleChange={handleChange} setShowEditModal={setShowEditModal} />
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
      {/* MODAL DESHABILITAR CLASIFICACION */}
      <div>
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Deshabilitar Clasificación</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere deshabilitar la Clasificación?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancelar
            </Button>
            <Button variant="danger" 
             onClick={handleDelete}
            >
              Deshabilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* MODAL BITACORA INDIVIDUAL */}
      <div>
        <Modal
          size='xl'
          show={showBitacoraIndividualModal}
          onHide={() => setShowBitacoraIndividualModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Bitácora Individual</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          { !readyContentBitacora ? 
          <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
          : (
            <>
            { !bitacoraIndividual.length > 0 ? (
                  <h6>No hay historial en la Bitácora</h6>
                ) :
                (
                  <div>
                    <AdvanceTableWrapper
                        columns={columnsBitacora}
                        data={bitacoraIndividual}
                        sortable
                        pagination
                        perPage={10}
                      >            
                        <AdvanceTable
                          title='Bítacora de Clasificaciones'
                          subtitle='Tabla de Bítacora de Clasificaciones'
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle"
                          rowClassName="align-middle white-space-nowrap"
                          tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                          }}
                        />
                        <div className="mt-3">
                          <AdvanceTableFooter
                            rowCount={bitacoraIndividual.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                          />
                        </div>
                      </AdvanceTableWrapper>
                    </div>
                )}
                </>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => setShowBitacoraIndividualModal(false)}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* MODAL BITACORA GENERAL */}
      <div>
        <Modal
          size='xl'
          show={showBitacoraGeneralModal}
          onHide={() => setShowBitacoraGeneralModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Bitácora General</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BitacoraClasificacion />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => setShowBitacoraGeneralModal(false)}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* MODAL DISABLED */}
      <div>
        <Modal
          size='xl'
          show={showDisabledModal}
          onHide={() => setShowDisabledModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Clasificaciones Deshabilitadas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DisabledClasificacion />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => {
                setShowDisabledModal(false)
                startResetApp()
              }}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default ClasificacionAdmin