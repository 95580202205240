import React, { useState, useEffect } from 'react'
import axios from 'utils/axios';
import { Card, Row, Col, Modal, Button, Spinner } from 'react-bootstrap'

import useAuth from 'hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { verifyAccess } from 'helpers';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import { columnsAtributos } from './data'
import IconButton from 'components/common/IconButton';

import ModalAtributos from './components/ModalAtributos.js'
import ModalBitacoraEntidad from './components/BitacoraEntidad.js'
import ModalBitacoraData from './components/BitacoraData.js'
import ModalAtributosDisabled from './components/ModalAtributosDisabled.js'

import { toastNotification, verifyResponseUnauthorizedAwait } from 'helpers/utils';
import useLoadingContext from 'hooks/useLoadingContext';

const AtributosAdmin = () => {
  const navigate = useNavigate();

  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  const [ atributos, setAtributos ] = useState([]);
  const [ atributosActions, setAtributosActions ] = useState([]);

  const [ tiposUnidadDeMedida, setTiposUnidadDeMedida ] = useState([]);
  const [ readyContent, setReadyContent ] = useState(false);

  //! VARIABLES PARA MOSTRAR MODALES
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);
  const [ showAtributoModal, setShowAtributoModal ] = useState(false);
  const [ showBitacoraEntidad, setShowBitacoraEntidad ] = useState(false);
  const [ showBitacoraData, setShowBitacoraData ] = useState(false);
  const [ showDisabled, setShowDisabled ] = useState(false);

  const [ idToDelete, setIdToDelete ] = useState(0);
  const [ idToUpdate, setIdToUpdate ] = useState(0);
  const [ atributoToUpdate, setAtributoToUpdate ] = useState({});

  const [ idEntidad, setIdEntidad ] = useState(2);
  const [ idBitacoraData, setIdBitacoraData ] = useState([]);

  //? INICIANDO LA APP
  useEffect(() => {
    const hasAccess = verifyAccess(user, 'profileAtributosAdmin', '/administrador/dashboard')
    if( !hasAccess ) navigate("/administrador/dashboard");
    if( hasAccess ) { 
      getAllMeasureUnit() 
      startResetApp();
    };
  }, [])


  //! ADAPTA LOS ATRIBUTOS PARA SER MOSTRADOS EN LA TABLA
  useEffect(() => {
    const atributosButton = atributos.map( atributo => {
      ( atributo.isDimensional ) ? atributo.isDimensional = 'Si' : atributo.isDimensional = 'No'
      // atributo.tipoMedida = atributo.typeMeasureUnit.description;
      atributo.tipoMedida = atributo.typeMeasureUnit.descripcion;
      atributo.acciones = [
        <IconButton
          title="Editar"
          variant="white"
          size="sm"
          icon="edit"
        style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setShowAtributoModal(true);
            setAtributoToUpdate(atributo);
            setIdToUpdate(atributo.id);
          }}
        ></IconButton>,
        <IconButton
          title="Deshabilitar"
          variant="white"
          size="sm"
          icon="trash-alt"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setShowDeleteModal(true)
            setIdToDelete( atributo.id )
          }}
        ></IconButton>,
        <IconButton
            title="Ver Bitácora"
            variant="white"
            size="sm"
            icon="book"
            style={{ boxShadow: "none", width: "33%" }}
            onClick={ () => {
              setIdBitacoraData(atributo.id)
              setShowBitacoraData(true)
            }}
        ></IconButton>
      ]
      return atributo
    })
    setAtributosActions(atributosButton);
  }, [ atributos ])

  const startResetApp = () => {
    Promise.all([ getAllAtributos(), getIdEntityByName() ])
      .then(([atributos, entidadByName ]) => {
        setReadyContent(true)
    })
    .catch(error => {
      toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
      setReadyContent(true)
    })
  }

  //! OBTENER ID DE LA ENTIDAD
  const getIdEntityByName = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/getIdEntityByTerm/atributos`
    try {
      const { data } = await axios.get(url);
      if(data.statusCode === 200) setIdEntidad(data.data[0].id);
    } catch (error) {
      console.error("Error al intentar obtener la entidad");
    }
  }


  //! OBTENER TODOS LOS ATRIBUTOS
  const getAllAtributos = async () => {
    setReadyContent(false)
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/getAllAtributos`;
    try {
      const { data } = await axios.get(url);
      setReadyContent(true)
      if(data.statusCode === 200) setAtributos(data.data);
    } catch (error) {
      console.error('Error al intentar obtener todos los atributos.');
      setReadyContent(true)
    }
  };
  
  
  //! OBTENER TODAS LAS UNIDADES DE MEDIDA
  const getAllMeasureUnit = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/getAllTypeMeasureUnit`;
    try {
      const { data } = await axios.get(url);
      if( verifyResponseUnauthorizedAwait(data, 1) ) return;
      if( data.statusCode === 200 ) setTiposUnidadDeMedida(data.data);
    } catch (error) {
      console.error("Error al intentar obtener todos los tipos de unidades de medida");
    }
    
  }
  
  //! CREAR NUEVO ATRIBUTO
  const createNewAtributo = async ( atributo ) => {
    if( atributo.idTipoMedida !== 4 ) atributo.listadoOpciones = [];
    
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/createNewAtributo`;
    try {
      setIsLoading(true);
      const { data } = await axios.post(url, atributo);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 2) ) return;
      if( data.statusCode == 201 ) toastNotification('success', 'Atributo creado correctamente');
      if( data.statusCode !== 201 ) toastNotification('warning', 'Ocurrió un error al intentar crear el atributo');
    } catch (error) {
      setIsLoading(false);
      console.error("Error al intentar crear el nuevo atributo");
      toastNotification('error', 'Ocurrió un error al intentar crear el atributo');
    }
    setShowAtributoModal(false);
    startResetApp()
  }
  
  //! ACTUALIZAR ATRIBUTO
  const updateAtributo = async ( atributo ) => {
    if( atributo.idTipoMedida !== 4 ) atributo.listadoOpciones = [];
    
    const { idToUpdate, ...rest } = atributo
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/updateAtributoById/${idToUpdate}`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url, rest);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 3) ) return;
      if(data.statusCode == 200) toastNotification('success', 'Atributo editado correctamente')
      if(data.statusCode !== 200) toastNotification('warning', 'Ocurrió un error al intentar editar el atributo');
    } catch (error) {
      setIsLoading(false);
      console.error("Error al intentar actualizar el atributo");
      toastNotification('warning', 'Ocurrió un error al intentar editar el atributo');
    }
    setShowAtributoModal(false);
    startResetApp()
  }

  //! HABILITAR ATRIBUTO
  const enableAtributo = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/enableAtributoById/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url)
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 3) ) return;
      if(data.statusCode !== 200) toastNotification('warning', 'Ocurrió un error al intentar habilitar el atributo')
      if( data.statusCode === 200 ) {
        toastNotification('success', 'Atributo habilitado correctamente')
        setShowDisabled(false)
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error al intentar habilitar el atributo");
      toastNotification('warning', 'Ocurrió un error al habilitar el atributo');
    }
    startResetApp()
  }

  //! ELIMIAR UN ATRIBUTO
  const deleteAtributo = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/deleteAtributoById/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.delete(url);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 4) ) return;
      if(data.statusCode === 200) toastNotification('success', 'Atributo deshabilitado correctamente')
      if(data.statusCode !== 200) toastNotification('warning', 'Ocurrió un error al deshabilitar el atributo')
    } catch (error) {
      setIsLoading(false);
      console.error("Error al intentar eliminar el atributo");
      toastNotification('warning', 'Ocurrió un error al eliminar el atributo');
    }
    setShowDeleteModal(false);
    startResetApp();
  }
  
  return (
    <>
      <Card.Body className="overflow-hidden p-lg-6">
          <Row className="justify-content-between">
            <Col lg={6} className="ps-lg-4 my-5text-lg-start">
              <h3 className="text-primary">Atributos</h3>
            </Col>
          </Row>

        { !readyContent ? 
        <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
        : (
          <Card className="h-md-100">
            <Card.Body>

              <AdvanceTableWrapper
                columns={columnsAtributos}
                data={atributosActions}
                sortable
                pagination
                perPage={5}
              >

                <Row className="flex-end-center mb-3">
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-success"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                          setShowAtributoModal(true)
                        }}
                      >
                        Nuevo Atributo
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-primary"
                        size="sm"
                        icon="book"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                          setShowBitacoraEntidad(true)
                        }}
                      >
                        Bitácora General
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-warning"
                        size="sm"
                        icon="trash"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                          setShowDisabled(true)
                        }}
                      >
                        Deshabilitados
                      </IconButton>
                    </div>
                    
                  </Col>
                </Row>
                
                <AdvanceTable
                  title='Atributos'
                  subtitle='Tabla de Atributos'
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />

                <div className="mt-3">
                  <AdvanceTableFooter
                    rowCount={atributos.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                  />
                </div>

              </AdvanceTableWrapper>
            </Card.Body>
          </Card>
        ) }
      </Card.Body>

      {/* MODALES */}
      <div>
        <ModalAtributos showAtributoModal={showAtributoModal} setShowAtributoModal={setShowAtributoModal} tiposUnidadDeMedida={tiposUnidadDeMedida} idToUpdate={idToUpdate} atributoToUpdate={atributoToUpdate} setAtributoToUpdate={setAtributoToUpdate} setIdToUpdate={setIdToUpdate} createNewAtributo={createNewAtributo} updateAtributo={updateAtributo} />
      </div>

      <div>
          <ModalBitacoraEntidad title={'Bitácora General'} idEntidad={idEntidad} showBitacoraEntidad={showBitacoraEntidad} setShowBitacoraEntidad={setShowBitacoraEntidad}/>
      </div>


      <div>
          <ModalBitacoraData title={'Bitacora del Dato'} idEntidad={idEntidad} idBitacoraData={idBitacoraData} showBitacoraData={showBitacoraData} setShowBitacoraData={setShowBitacoraData}/>
      </div>

      <div>
          <ModalAtributosDisabled title={'Atributos deshabilitados'} showDisabled={showDisabled} setShowDisabled={setShowDisabled} habilitarAtributo={enableAtributo} />
      </div>

      <div>{/* INICIO MODAL PARA ELIMINAR PROPUESTA */}
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Deshabilitar Atributo</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro de deshabilitar el atributo?</Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancelar
            </Button>
            <Button 
              variant="danger"
              disabled={isLoading}
              onClick={() => { 
                deleteAtributo(idToDelete)
              }}
            >
              Deshabilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>{/* FIN MODAL PARA ELIMINAR PROPUESTA */}

    </>
  )

}

export default AtributosAdmin