import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActiveUsersChart from '../../components/GraficoPropuestasChart';
import { formatterPesoChile } from "helpers/utils";

const ActiveUsers = ({dataSituacionAnterior, periodo, value}) => {
  return (
    <Card>
      <FalconCardHeader
        title="Situación Anterior"
        titleTag="h6"
        light
      />
      <Card.Body>

        <Row>
          <Col xs={12}>
            {/* STATS MONTO */}
            <Row className="flex-md-column justify-content-between h-md-100">
              <Col className='text-center'>
                <h6 className="fs--2 text-7">
                  <FontAwesomeIcon
                    icon="circle"
                    className='text-info me-2'
                  />
                  S/Ant
                </h6>
                <h5 className="text-700 fs-0">
                  {(value > 0) ? (formatterPesoChile(value)) : ('0')}
                </h5>
              </Col>
            </Row>
          </Col>

          {/* GRAFICO */}
          <Col xs={12}>
            <ActiveUsersChart propuesta={dataSituacionAnterior} periodo={periodo} />
          </Col>

        </Row>
      </Card.Body>
    </Card>
  );
};

export default ActiveUsers;
