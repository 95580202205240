import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActiveUsersChart from '../../components/GraficoPropuestasChart';
import { formatterPesoChile, graficar } from 'helpers/utils';
import moment from 'moment';

const GraficoPropuestas = ({ propuesta, costosPropuesta, situacionAnterior, costosSituacionAnterior, periodoPropuestaSelect, handleTotal, ...rest }) => {
  
  const [ periodo, setPeriodo ] = useState();
  const [ objToGraf, setObjToGraf ] = useState({
    promather: [],
    anterior: []
  });


  const [stats, setStats] = useState([
    { label: 'S/Ant', color: 'danger', value: 0 },
    { label: `Prop`, color: 'primary', value: 0 }
  ]);

  useEffect(() => {
    if( costosPropuesta.length < 1 || propuesta == undefined ) return
    setearLineas();
  }, [costosPropuesta])

  const setearLineas = () => {

    // let lineCostos = objToGraf;
    let lineCostos = {};
    const fechaInicio = moment().format();
    const fechaTermino = moment(fechaInicio).add(propuesta.periodo, 'days').format()

    if(costosSituacionAnterior.length > 0){
      lineCostos.anterior = graficar(fechaInicio, fechaTermino, costosSituacionAnterior)
    }else{
      lineCostos.anterior = graficar(fechaInicio, fechaTermino, [])
    }
    
    if( costosPropuesta.length > 0 ){
      lineCostos.promather = graficar(fechaInicio, fechaTermino, costosPropuesta, setPeriodo)
    }else{
      lineCostos.promather = graficar(fechaInicio, fechaTermino, [], setPeriodo)
    }

    setObjToGraf(lineCostos)
  }


  useEffect(() => {
    if( objToGraf.promather.length < 1 || objToGraf.anterior.length < 1 ) return
    const stat = [
      {label: 'S/Ant', color: 'danger', value: objToGraf.anterior[objToGraf.anterior.length - 1] },
      {label: 'Prop', color: 'primary', value: objToGraf.promather[objToGraf.promather.length - 1] }
    ]
    setStats(stat);    
  }, [objToGraf.promather])
  
  useEffect(() => {
    if( stats[0].value < 1 || stats[1].value < 1 ) return
    handleTotal(stats[0].value, stats[1].value)
  }, [stats])

  
  return (
    <Card {...rest}>
      <FalconCardHeader
        title={ !propuesta.id ? ('Propuesta vs Situación Anterior') : ( `${propuesta.nombre} vs Situación Anterior` ) }
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body>
        { !propuesta.id ? (
          <h6 className='text-center'>Ninguna propuesta seleccionada</h6>
        ) : (
          <Row>
            <Col xs={12} className="mt-md-0">
              <Row className="mb-3 text-center">
                {stats.map((stat) => (
                  <Col key={stat.label} className='border-end' >
                    <h6 className="fs--2 text-7">
                      <FontAwesomeIcon
                        icon="circle"
                        className={`text-${stat.color} me-2`}
                      />
                      {stat.label}
                    </h6>
                    <h5 className="text-700 fs-0">
                      {formatterPesoChile(stat.value)}
                    </h5>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className="echart-active-users-report-container w-75">
              <ActiveUsersChart key={'keyPrpopuesta'} situacionAnterior={objToGraf.anterior} propuesta={objToGraf.promather} situacionReal={[]} fechaInicio={null} fechaTermino={null} />
            </Col>
          </Row>
        ) }
      </Card.Body>
    </Card>
  );
};

export default GraficoPropuestas;
