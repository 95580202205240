import {lazy, useContext, useEffect} from "react";
import {Navigate, useRoutes} from "react-router-dom";
import is from 'is_js';
// components
import Loadable from "components/Loadable";
// context
import AppContext from 'context/Context';
// layouts
import LogoOnlyLayout from "layouts/LogoOnly";
import LandingLayout from "layouts/landing";
import MainLayout from "layouts/MainLayout";
import AuthGuard from "../guards/AuthGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import {profiles} from "../data/profiles";
import GuestGuard from "../guards/GuestGuard";
import AttributeProfileGuard from "../guards/AttributeProfileGuard";

import FirstLogin from "components/authentication/FirstLogin"
import RecuperarPassword from "components/authentication/RecuperarPassword"


//! ********* PAGES **********

//! COMPONENTES DEL VENDEDOR
import { Inicio } from "pages/vendedor/inicio/Inicio";
import Mensajes from "pages/vendedor/mensajes/Mensajes";
import Biblioteca from "pages/vendedor/biblioteca/Biblioteca";
import { Equipo } from "pages/vendedor/equipos/equipo/Equipo";

//! COMPONENTES DEL CLIENTE
import InicioCliente from "pages/cliente/inicio/InicioCliente";
import MensajesCliente from "pages/cliente/mensajes/MensajesCliente";
import { EquipoCliente } from "pages/cliente/equipos/equipo/EquipoCliente";

//! COMPONENTES DEL ADMIN
//? Inicio
import InicioAdmin from "pages/admin/inicio/InicioAdmin"
//? Usuarios
import PerfilesAdmin from "pages/admin/usuarios/PerfilesAdmin"
import UsuariosAdmin from "pages/admin/usuarios/UsuariosAdmin"
//? Equipos
import AtributosAdmin from "pages/admin/equipos/AtributosAdmin"
import TiposEquiposAdmin from "pages/admin/equipos/TiposEquiposAdmin"
//? Mantenedores
import IndustriaAdmin from "pages/admin/mantenedores/industria/IndustriaAdmin"
import ClasificacionAdmin from "pages/admin/mantenedores/clasificacion/ClasificacionAdmin"
import CargosAdmin from "pages/admin/mantenedores/cargo/CargosAdmin"
import TiposMensajesAdmin from "pages/admin/mantenedores/tipoMensajes/TiposMensajesAdmin";
import MarcaAdmin from "pages/admin/mantenedores/marca/MarcaAdmin";
import ModeloAdmin from "pages/admin/mantenedores/modelo/ModeloAdmin";
import TipoAreaAdmin from "pages/admin/mantenedores/tipoArea/tipoAreaAdmin";
import TipoLineaAdmin from "pages/admin/mantenedores/tipoLinea/tipoLineaAdmin";
import PaisesAdmin from "pages/admin/mantenedores/paises/PaisesAdmin";
import RegionesAdmin from "pages/admin/mantenedores/regiones/RegionesAdmin";
import CiudadesAdmin from "pages/admin/mantenedores/ciudades/CiudadesAdmin";
import CorporativoAdmin from "pages/admin/clientes/corporativo/CorporativoAdmin";
import PlantaAdmin from "pages/admin/clientes/planta/PlantaAdmin";
import LineaAdmin from "pages/admin/clientes/linea/LineaAdmin";
import AreaAdmin from "pages/admin/clientes/area/AreaAdmin";
import EstadoPropuestaAdmin from "pages/admin/mantenedores/estadoPropuesta/EstadoPropuestaAdmin";
import TipoCostoAdmin from "pages/admin/mantenedores/tipoCosto/TipoCostoAdmin";
import TipoUnidadMedidaAdmin from "pages/admin/mantenedores/tipoUnidadMedida/TipoUnidadMedidaAdmin";
import { CategoriaMensaje } from "pages/admin/mantenedores/CategoriaMensaje/CategoriaMensaje";
import EquipoAdmin from "pages/admin/equipos/equipo/EquipoAdmin";
import { AvisoMantencionesPage } from "pages/admin/mantenedores/AvisoMantenciones/page/AvisoMantencionesPage";

import UnidadMedidaAdmin from "pages/admin/mantenedores/unidadMedida/UnidadMedidaAdmin";
import TamañosEquiposAdmin from "pages/admin/mantenedores/tamañoEquipo/TamañoEquipo";


//! COMMON
import { InformacionDimensional } from "pages/common/detalleEquipo/InformacionDimensional";
import { InformacionOperacional } from "pages/common/detalleEquipo/InformacionOperacional";
import { Reportes } from "pages/common/detalleEquipo/reportes/Reportes"
import { MensajesDetalle } from "pages/common/detalleEquipo/mensajes/Mensajes"
import { Literatura } from "pages/common/detalleEquipo/literatura/Literatura"
import { Mantenciones } from "pages/common/detalleEquipo/mantenciones/Mantenciones";
import { SituacionAnterior } from "pages/common/detalleEquipo/mantenciones/situacionAnterior/SituacionAnterior";
import { Propuestas } from "pages/common/detalleEquipo/mantenciones/Propuestas/Propuestas";
import { SituacionReal } from "pages/common/detalleEquipo/mantenciones/SituacionReal/SituacionReal";
import { SituacionesRealesAnteriores } from "pages/common/detalleEquipo/mantenciones/SituacionesRealesAnteriores/SituacionesRealesAnteriores";
import EstadisticasAdmin from "pages/common/estadisticas/ConexionesClientes"
import { ConexionesUsuarios } from "pages/common/estadisticas/ConexionesUsuarios/ConexionesUsuarios"
import { AccesosEquipos } from "pages/common/estadisticas/AccesosEquipos/AccesosEquipos";
import { EstadisticaEquipoPage } from "pages/common/detalleEquipo/estadistica/EstadisticaEquipoPage";
import { EquiposPage } from "pages/equipos/page/EquiposPage";





// ----------------------------------------------------------------------

export default function Router() {
    const HTMLClassList = document.getElementsByTagName('html')[0].classList;
    useContext(AppContext);

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add('windows');
        }
        if (is.chrome()) {
            HTMLClassList.add('chrome');
        }
        if (is.firefox()) {
            HTMLClassList.add('firefox');
        }
    }, [HTMLClassList]);

    return useRoutes([
        {
            path: "vendedor",
            element:  <AuthGuard><RoleBasedGuard accessibleRoles={[profiles.vendedor]}><MainLayout/></RoleBasedGuard></AuthGuard>,
            children: [
                {path: "", element: <Navigate to="/vendedor/inicio" replace/>},
                {path: "inicio", element: <Inicio/>},
                {path: "equipos", element: <EquiposPage/>},
                {path: "mensajes", element: <Mensajes/>},
                {path: "biblioteca", element: <Biblioteca/>},
                {path: "equipo/:id", element: <Equipo/>, children: [
                    // {path: "", element: <Navigate to="dimensional" replace/>},
                    {path: "", element: ''},
                    {path: "dimensional", element: <AttributeProfileGuard identifier={'profileDimensional'}><InformacionDimensional/></AttributeProfileGuard>},
                    {path: "operacional", element: <AttributeProfileGuard identifier={'profileOperational'}><InformacionOperacional/></AttributeProfileGuard>},
                    {path: "reportes", element: <AttributeProfileGuard identifier={'profileReport'}><Reportes/></AttributeProfileGuard>},
                    {path: "mensajes", element: <AttributeProfileGuard identifier={'profileMessage'}><MensajesDetalle/></AttributeProfileGuard>},
                    {path: "literatura", element: <Literatura/>},
                    {path: "mantenciones", element: <AttributeProfileGuard identifier={'profileEconomic'}><Mantenciones/></AttributeProfileGuard> , children:[
                        {path:"", element: <Navigate to="anterior" replace/>},
                        {path:"anterior", element: <SituacionAnterior/>},
                        {path:"propuestas", element: <Propuestas/>},
                        {path:"real", element:<SituacionReal/>},
                        {path:"real-anterior", element:<SituacionesRealesAnteriores/>}
                    ]},
                    {path: "estadistica", element: <EstadisticaEquipoPage/>},
                ]}
            ]
        },        
        {
            path: "cliente",
            element: <AuthGuard><RoleBasedGuard accessibleRoles={[profiles.cliente]}><MainLayout/></RoleBasedGuard></AuthGuard>,
            children: [
                {path: "", element: <Navigate to="/cliente/inicio" replace/>},
                {path: "inicio", element: <InicioCliente/>},
                {path: "equipos", element: <EquiposPage/>},
                {path: "mensajes", element: <MensajesCliente/>},
                {path: "biblioteca", element: <Biblioteca/>}, 
                {path: "equipo/:id", element: <EquipoCliente/>, children: [
                    // {path: "", element: <Navigate to="dimensional" replace/>},
                    {path: "", element: ''},
                    {path: "dimensional", element: <AttributeProfileGuard identifier={'profileDimensional'}><InformacionDimensional/></AttributeProfileGuard>},
                    {path: "operacional", element: <AttributeProfileGuard identifier={'profileOperational'}><InformacionOperacional/></AttributeProfileGuard>},
                    {path: "reportes", element: <AttributeProfileGuard identifier={'profileReport'}><Reportes/></AttributeProfileGuard>},
                    {path: "mensajes", element: <AttributeProfileGuard identifier={'profileMessage'}><MensajesDetalle/></AttributeProfileGuard>},
                    {path: "literatura", element: <Literatura/>},
                    {path: "mantenciones", element: <AttributeProfileGuard identifier={'profileEconomic'}><Mantenciones/></AttributeProfileGuard> , children:[
                        {path:"", element: <Navigate to="anterior" replace/>},
                        {path:"anterior", element: <SituacionAnterior/>},
                        {path:"propuestas", element: <Propuestas/>},
                        {path:"real", element:<SituacionReal/>},
                        {path:"real-anterior", element:<SituacionesRealesAnteriores/>}
                    ]},
                    {path: "estadistica", element: <EstadisticaEquipoPage/>},
                ]}
            ]
        },
        {
            path: "administrador",
            element: <AuthGuard><RoleBasedGuard accessibleRoles={[profiles.administrador]}><MainLayout/></RoleBasedGuard></AuthGuard>,
            children: [
                {path: "", element: <Navigate to="/administrador/dashboard" replace/>},
                {path: "dashboard", element: <InicioAdmin/>},
                {path: "estadistica", children: [
                    {path: "conexionesClientes", element: <EstadisticasAdmin/>},
                    {path: "conexionesUsuarios", element: <ConexionesUsuarios/>},
                    {path: "accesosEquipos", element: <AccesosEquipos/>},
                ]},
                {path: "perfiles", element: <PerfilesAdmin/>},
                {path: "usuarios", element: <UsuariosAdmin/>},
                {path: "corporativo", element: <CorporativoAdmin/>},
                {path: "planta", element: <PlantaAdmin/>},
                {path: "linea", element: <LineaAdmin/>},
                {path: "area", element: <AreaAdmin/>},
                {path: "atributos", element: <AtributosAdmin/>},
                {path: "tiposEquipos", element: <TiposEquiposAdmin/>},
                {path: "equipos", element: <EquipoAdmin/>},
                {path: "industria", element: <IndustriaAdmin/>},
                {path: "clasificacion", element: <ClasificacionAdmin/>},
                {path: "marca", element: <MarcaAdmin/>},
                {path: "modelo", element: <ModeloAdmin/>},
                {path: "cargo", element: <CargosAdmin/>},
                {path: "tipoArea", element: <TipoAreaAdmin/>},
                {path: "tipoLinea", element: <TipoLineaAdmin/>},
                {path: "tiposMensajes", element: <TiposMensajesAdmin/>},
                {path: "paises", element: <PaisesAdmin/>},
                {path: "regiones", element: <RegionesAdmin/>},
                {path: "ciudades", element: <CiudadesAdmin />},
                {path: "estadoPropuesta", element: <EstadoPropuestaAdmin />},
                {path: "tipoCosto", element: <TipoCostoAdmin />},
                {path: "unidadMedida", element: <UnidadMedidaAdmin />},
                {path: "tipoUnidadMedida", element: <TipoUnidadMedidaAdmin />},
                {path: "sizeEquipos", element: <TamañosEquiposAdmin />},
                {path: "categoriaMensaje", element: <CategoriaMensaje />},
                {path: "avisoMantenciones", element: <AvisoMantencionesPage />},
                {path: "equipo/:id", element: <Equipo/>, children: [
                    // {path: "", element: <Navigate to="dimensional" replace/>},
                    {path: "", element: ''},
                    {path: "dimensional", element: <AttributeProfileGuard identifier={'profileDimensional'}><InformacionDimensional/></AttributeProfileGuard>},
                    {path: "operacional", element: <AttributeProfileGuard identifier={'profileOperational'}><InformacionOperacional/></AttributeProfileGuard>},
                    {path: "reportes", element: <AttributeProfileGuard identifier={'profileReport'}><Reportes/></AttributeProfileGuard>},
                    {path: "mensajes", element: <AttributeProfileGuard identifier={'profileMessage'}><MensajesDetalle/></AttributeProfileGuard>},
                    {path: "literatura", element: <Literatura/>},
                    {path: "mantenciones", element: <AttributeProfileGuard identifier={'profileEconomic'}><Mantenciones/></AttributeProfileGuard> , children:[
                        {path:"", element: <Navigate to="anterior" replace/>},
                        {path:"anterior", element: <SituacionAnterior/>},
                        {path:"propuestas", element: <Propuestas/>},
                        {path:"real", element:<SituacionReal/>},
                        {path:"real-anterior", element:<SituacionesRealesAnteriores/>}
                    ]},
                    {path: "estadistica", element: <EstadisticaEquipoPage/>},
                ]}
            ]
        },
        {
            path: '/firstLogin/',
            element: <AuthGuard><FirstLogin /></AuthGuard>
        },
        {
            path: "*",
            element: <LogoOnlyLayout/>,
            children: [
                {path: "404", element: <NotFound/>},
                {path: "*", element: <Navigate to="/404" replace/>},
            ],
        },
        {
            path: "/",
            element: <GuestGuard><LandingLayout/></GuestGuard>,
            // children: [{path: "", element: <Landing/>}],
        },
        {
            path: "/recuperarPassword/:token",
            element: <RecuperarPassword></RecuperarPassword>,
            // children: [{path: "", element: <Landing/>}],
        },
        // {path: "", element: <Navigate to="/404" replace/>},
    ]);
}

const NotFound = Loadable(lazy(() => import("pages/Page404")));
// const Landing = Loadable(lazy(() => import("pages/landing/Landing")));
