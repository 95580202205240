import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { statsData } from './crm';
import classNames from 'classnames';
import IconItem from 'components/common/icon/IconItem';
import { formatterPesoChile } from 'helpers/utils';
import FalconCardHeader from 'components/common/FalconCardHeader';

const StatsItem = ({ stat, propuesta, totalPropuesta, totalAnterior }) => {
  const { icon, color, title, amount, caret, caretColor, target, data } = stat;
  const [percent, setPercent] = useState(0);
  
  useEffect(() => {
    calcularPercent(totalPropuesta, totalAnterior);
  }, [totalPropuesta, totalAnterior])


  const calcularPercent = (prop, ant) => {
    let porcentaje
    if( prop > ant){
      porcentaje = ((ant * 100) / prop);
      porcentaje = (porcentaje - 100)
      porcentaje = (porcentaje * -1).toString()
      porcentaje = porcentaje.slice(0, 4)
      setPercent(Number(+porcentaje))
    }else{
      porcentaje = ((prop * 100) / ant);
      porcentaje = (porcentaje - 100)
      porcentaje = (porcentaje * -1).toString()
      porcentaje = porcentaje.slice(0, 4)
      setPercent(Number(+porcentaje))
    }

  }
  

  return (
    <>
      { !propuesta.id ? (
        <h6>Ninguna propuesta seleccionada</h6>
        ) : (
        <div>
          <Flex
            justifyContent="center"
            alignItems="center"
            className="mb-3 mt-4 position-static"
          >
            <IconItem
              tag="div"
              icon='bolt'
              bg={`soft-success`}
              color='success'
              size="sm"
              iconClass="fs--2"
              className="me-2 shadow-none"
            />
            <h6 className="mb-0 flex-1">{propuesta.nombre}</h6>
          </Flex>
          <Flex>
            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{isNaN(percent) ? '0': percent}%</p>
            <div className="d-flex flex-column">
              { (totalPropuesta > totalAnterior) ? (
                <FontAwesomeIcon
                  icon='caret-up'
                  className={`me-1 mb-0 text-danger`}
                />
                ) : (
                  <FontAwesomeIcon
                  icon='caret-down'
                  className={`me-1 mb-0 text-success`}
                />
              ) }
              <p className="fs--2 mb-0 mt-0 text-nowrap">{isNaN(percent) ? (
                '0 vs 0'
              ) : (
                <p>
                  <span className='fw-bold me-1'>{formatterPesoChile(totalPropuesta)}</span>
                  vs
                  <span className='fw-bold ms-1'>{formatterPesoChile(totalAnterior)}</span>
                </p>
              )}
              </p>
            </div>
            <div className="w-100 ml-5">
            </div>
          </Flex>
        </div>
      ) }
    </>
  );
};

const CrmStats = ({ propuesta, totalPropuesta, totalAnterior }) => {
  const [stats] = useState(statsData);
  return (
    <Card>
      <FalconCardHeader
        title="Ahorro Propuesta"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body>
        <Row>
          {stats.map((stat, index) => (
            <Col
              lg={8}
              key={stat.id}
              className={classNames({
                'border-bottom border-lg-0 border-lg-end':
                  index !== stats.length - 1,
                'pb-3 pb-lg-0': index === 0,
                'py-3 py-lg-0': index === 1,
                'pt-3 pt-lg-0': index === 2
              })}
            >
              <StatsItem stat={stat} propuesta={propuesta} totalPropuesta={totalPropuesta} totalAnterior={totalAnterior} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

StatsItem.propTypes = {
  stat: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    caret: PropTypes.string.isRequired,
    caretColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    icon: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    target: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })
};

CrmStats.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape(StatsItem.propTypes.stat))
};

export default CrmStats;
