import React, { useEffect, useState } from 'react';
import { Button, Card, CloseButton, Col, Spinner, Modal, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import CrmStats from './ahorro/CrmStats';
import GraficoSituacionReal from './grafico/GraficoSituacionReal';
import {columns, columnsCliente} from './data';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { NuevoItem } from './nuevoItem';
import { useParams, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import axios from 'utils/axios';
import { toastNotification, formatterPesoChile, graficar, getDaysBetweenDates } from "helpers/utils";
import { EditarItem } from './editarItem';
import moment from 'moment';
import { DetenerSituacionReal } from './detenerSituacionReal';
import ValidateAccessEquipment from 'components/pages/ValidateAccessEquipment';
import TipoPerfilEnum from 'utils/TipoPerfilEnum';
import useLoadingContext from 'hooks/useLoadingContext';
import ReactTooltip from 'react-tooltip';

export const SituacionReal = () => {
  const navigate = useNavigate();

  const { user } = useAuth();
  const { setIsLoading } = useLoadingContext();

  let params = useParams();

  const [showDetenerModal, setShowDetenerModal] = useState(false);
  const [showDeleteCostoModal, setShowDeleteCostoModal] = useState(false);
  const [deleteCosto, setDeleteCosto] = useState();
  const [showEditCostoModal, setShowEditCostoModal] = useState(false);
  const [editarCosto, setEditarCosto] = useState();
  const [showItemModal, setShowItemModal] = useState(false);

  const [ situacionReal, setSituacionReal] = useState();
  const [ costosReales, setCostosReales] = useState([]);
  const [ costosRealesActions, setCostosRealesActions] = useState([]); //Nueva
  const [ costosAnteriores, setCostosAnteriores] = useState([]);
  const [ costosPropuesta, setCostosPropuesta] = useState([]);
  
  const [ fechaInicio, setFechaInicio] = useState();
  const [ fechaFinal, setFechaFinal] = useState();

  const [ readyContent, setReadyContent ] = useState(false);
  
  const [ fechaProyectadaRetorno, setFechaProyectadaRetorno] = useState();
  const [ fechaRetorno, setFechaRetorno] = useState();

  const [ graficarSituacionAnterior, setGraficarSituacionAnterior] = useState([]);
  const [ graficarPropuesta, setGraficarPropuesta] = useState([]);
  const [ graficarSituacionReal, setGraficarSituacionReal ] = useState([]);

  const [dataSituacionRealValorFecha, setDataSituacionRealValorFecha] = useState([]);
  const [dataPropuestaValorFecha, setDataPropuestaValorFecha] = useState([]);
  const [dataSituacionAnteriorValorFecha, setDataSituacionAnteriorValorFecha] = useState([]);

  const startResetApp = async () => {
    getSituacionReal();
    getPropuesta();
    getSituacionAnterior();
    setReadyContent(true);
  }

  // DATA SITUACION REAL
  const getSituacionReal = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionReal/getSituacionRealByIdEquipment/${params.id}`;
    const { data } = await axios.get(url);

    if(data.statusCode === 200){
      setSituacionReal(data.data[0]);
      getCostosSituacionReal(data.data[0].id)
    }
  }

  const getCostosSituacionReal = async ( id ) => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionReal/getCostosByIdSituacion/${id}`
    const { data } = await axios.get(url);
    if( data.statusCode === 200) setCostosReales(data.data)
  }

  //DATA PROPUESTA
  const getPropuesta = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getPropuestaInstaladaByIdEquipment/${params.id}`;
    const { data } = await axios.get(url);
    if(data.statusCode === 200){
      getCostosPropuesta(data.data[0].id)
    }
  }

  const getCostosPropuesta = async ( id ) => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getCostosByIdPropuestaInstalada/${id}`
    const { data } = await axios.get(url);
    if( data.statusCode === 200) setCostosPropuesta(data.data)
  }

  // DATA SITUACION ANTERIOR
  const getSituacionAnterior = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionAnterior/getSituacionAnteriorByIdEquipment/${params.id}`;
    const { data } = await axios.get(url);
    if(data.statusCode === 200){
      getCostosSituacionAnterior(data.data[0].id)
    }
  }

  const getCostosSituacionAnterior = async ( id ) => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionAnterior/getCostosByIdSituacion/${id}`
    const { data } = await axios.get(url);
    if( data.statusCode === 200) setCostosAnteriores(data.data)
  }

  // ACCIONES SITUACION REAL
  useEffect(() => {
    let isCliente = user.idTypeProfile == TipoPerfilEnum.cliente ? true : false;
    costosReales.forEach( e => {
      const tipoCosto = e.tipoCosto;
      e.tipoCosto =  tipoCosto.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
      e.valorFormat = formatterPesoChile(e.valor);
      e.fechaCostoFormat = moment(e.fechaCosto).format('DD-MM-YYYY')
      e.descripcionCorta = <div data-tip data-for={`tooltipDescripcion-${e.id}`} >
        <ReactTooltip id={`tooltipDescripcion-${e.id}`} className={'tooltipReact w-auto'} delayShow={50} >{ e.descripcion }</ReactTooltip>
        { e.descripcion.split(" ").length > 2 ? e.descripcion.split(" ").slice(0,2).join(' ') + '...' : e.descripcion }
      </div>
      e.accion = [
        <IconButton
          className={ isCliente ? 'd-none' : '' }
          title="Editar"
          variant="white"
          size="sm"
          icon="edit"
          style={{ boxShadow: "none", width: "50%" }}
          onClick={() => {
            setShowEditCostoModal(!showEditCostoModal);
            setEditarCosto(e);
          }}
        ></IconButton>,
        <IconButton
          className={ isCliente ? 'd-none' : '' }
          title="Eliminar"
          variant="white"
          size="sm"
          icon="trash-alt"
          style={{ boxShadow: "none", width: "50%" }}
          onClick={() => {
            setShowDeleteCostoModal(!showDeleteCostoModal);
            setDeleteCosto(e.id);
          }}
        ></IconButton>
      ];
    } )
    setCostosRealesActions(costosReales)
  }, [costosReales]);

  //FECHA INICIO Y TERMINO GRAFICAR SITUACION REAL
  useEffect(() => {
    if(situacionReal){
      const inicio = moment(situacionReal.fecha_inicio);
      const termino = moment(inicio).add(situacionReal.periodo, 'days');
      termino.set('hour', 4).set('minute', 0).set('second', 0)
      inicio.set('hour', 4).set('minute', 0).set('second', 0)
      const fechaActual = moment().add(1,'day');
      setFechaInicio(inicio.format())
      
      if(termino < fechaActual){
        setFechaFinal(fechaActual.format())
      }else{
        setFechaFinal(termino.format())
      }
    }
  },[situacionReal])

  //GRAFICAR SITUACION REAL
  useEffect(() => {
    if(costosReales){
      const arrayValorFecha = [];
      const arreglo = [];
      const arregloValor = [];
      const data = [];
      const arregloFinal = [];
      costosReales.forEach((element) => {
        arrayValorFecha.push({ valor: element.valor, fecha: moment(element.fechaCosto)});
      })
      if(situacionReal){
        const fecha = situacionReal.fecha_inicio;
        const fechaInicio = moment(fecha);
        const fechaTermino = moment();
        const diferenciaPeriodo = fechaTermino.diff(fechaInicio, 'days') + 1;
        arrayValorFecha.forEach((element) => {
          var valor = 0;
          for (let i = 0; i < diferenciaPeriodo; i++) {
            const fechaAcumulado = moment(fecha).add(i, 'days').format('DD-MM-YYYY');
            const fechaElement = moment(element.fecha).format('DD-MM-YYYY');
  
            if(fechaElement === fechaAcumulado){
              var valor = element.valor;
            }
            data.push({valor: valor, fecha: fechaAcumulado});
          }
        })

        for (const iterator of data) {
          arreglo.push(iterator);
        }
        const cantidad = arreglo.length / diferenciaPeriodo;
        for(let index = 0; index < cantidad; index++){
          for(let i = 0; i < diferenciaPeriodo; i++){
            if(arregloFinal.length >= diferenciaPeriodo){
              arregloFinal[i].valor += arreglo[i].valor; 
              arregloValor[i] += arreglo[i].valor;
            }else{
              arregloFinal.push(arreglo[i])
              arregloValor.push(arreglo[i].valor)
            }
          }
          arreglo.splice(0,diferenciaPeriodo);
        }
      }

      const costosAlt = costosReales.map( costo => {
        const dia = getDaysBetweenDates(situacionReal.fecha_inicio, costo.fechaCosto); 
        costo.diaCosto = dia;
        //! CHECKEAR ESTO -> ANTES ESTABA SOBRESCRIBIENDO DATOS
        costo.periodo = 0;
        return costo
      })

      const a = []
      if(costosAlt[0] !== undefined){
        const a = graficar(situacionReal.fecha_inicio, moment().add(1, 'day').format(), costosAlt)

        if (costosAlt.length > 0) {
          const a = graficar(moment(situacionReal.fecha_inicio).format('YYYY-MM-DD'), moment().add(1, 'day').format('YYYY-MM-DD'), costosAlt);
          setDataSituacionRealValorFecha(arregloFinal);
          setGraficarSituacionReal(a);
        }
      }
    }
  }, [situacionReal, costosReales]);

  //GRAFICAR SITUACION ANTERIOR
  useEffect(() => {
    if(situacionReal){
      const graficarCostosAnteriores = graficar(fechaInicio, fechaFinal, costosAnteriores);
      setGraficarSituacionAnterior(graficarCostosAnteriores);
      const line = agregarValorFecha(graficarCostosAnteriores, situacionReal.fecha_inicio)
      setDataSituacionAnteriorValorFecha(line);
    }
  }, [costosAnteriores, situacionReal])
  
  //GRAFICAR PROPUESTA
  useEffect(() => {
    if(situacionReal){
      const inicio = situacionReal.fecha_inicio;
      const termino = moment(inicio).add(situacionReal.periodo, 'days').format();
      const graficarCostosPropuesta = graficar(inicio, termino, costosPropuesta);
      setGraficarPropuesta(graficarCostosPropuesta);
      const line = agregarValorFecha(graficarCostosPropuesta, situacionReal.fecha_inicio)
      setDataPropuestaValorFecha(line);
    }
  }, [costosPropuesta, situacionReal])


  useState( async () => {
    const hasAccess = await ValidateAccessEquipment(user, params.id);
    if( !hasAccess ) navigate("/vendedor/inicio");
    if(hasAccess) startResetApp();
  }, [])
  
  // ELIMINAR COSTOS SITUACION REAL
  const handleDeleteCosto = async () => {
    setIsLoading(true);
    await axios.delete(`${process.env.REACT_APP_EQUIPO_SERVICE}/situacionReal/${deleteCosto}`)
      .then(() => {
        setIsLoading(false)
        startResetApp();
        setShowDeleteCostoModal(false)
      })
      .catch((err) => {
        setIsLoading(false)
        toastNotification('error', 'No se pudo Eliminar.', 2000)
        setTimeout(() => {
          setShowDeleteCostoModal(false);
        }, 2000);
      })
  }

    // ACTUALIZAR USEEFFECT CARGA DATA 
    const handleChangeNuevoItem = (data) => {
      startResetApp()
    }
    
    // ACTUALIZAR USEEFFECT CARGA DATA 
    const handleChangeEditarItem = (data) => {
      startResetApp()
    }  

        // ACTUALIZAR USEEFFECT CARGA DATA 
        const handleChangeDetener = (data) => {
          startResetApp()
        } 
  
  //AGREGA FECHA A LOS ARREGLOS
  const agregarValorFecha = (costos, fechaInicio) => {
    const arrayValorFecha = [];
    costos.forEach((e, index) => {
      const inicio = moment(fechaInicio);
      const fecha = inicio.add(index, 'day').format('DD-MM-YYYY') 
      arrayValorFecha.push({valor: e, fecha: fecha})
    }) 
    return arrayValorFecha;
  }

  // RETORNO PROYECTADO INVERSION
  useEffect(() =>{
    if(situacionReal){
      const arreglo = [];
      dataSituacionAnteriorValorFecha.forEach((element) => {
        for (let i = 0; i < dataPropuestaValorFecha.length; i++) {
          if(dataSituacionAnteriorValorFecha[i]?.valor >= dataPropuestaValorFecha[i]?.valor){
            arreglo.push(dataSituacionAnteriorValorFecha[i].fecha);
          }
        }
      })
          setFechaProyectadaRetorno(arreglo[0])
    }
  },[dataSituacionAnteriorValorFecha, dataPropuestaValorFecha, situacionReal])

  //RETORNO INVERSION
  useEffect(() =>{
    if(situacionReal){
      const arreglo = [];
      for (let i = 0; i < dataSituacionRealValorFecha.length; i++) {
        if(dataSituacionAnteriorValorFecha[i]?.valor >= dataSituacionRealValorFecha[i]?.valor){
          arreglo.push(dataSituacionRealValorFecha[i].fecha);
        }
      }
      setFechaRetorno(arreglo[0])
    }
  },[dataSituacionAnteriorValorFecha, dataSituacionRealValorFecha, situacionReal])

  return (
    <>
    { !readyContent ? 
      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>  
      : (
        <>
          <div>
          { !situacionReal ? (
                    <>
                      <div className='my-5 py-5 text-center'>
                        <p className='fs-3'>Este equipo no posee ninguna Situación Real Actualmente</p>
                      </div>
                    </>
                  ) : (
            <>
            <Row className='m-0 mb-4'>
              <Card>
                <Card.Body>
                    <>
                      <Row className='d-flex gap-1 justify-content-center'>
                        
                        <Col xs={12} lg={3} className='mt-2' >
                          <Row >
                            <p>Fecha Instalación:</p>
                            <p className=" fs--4 fw-bold">{moment(fechaInicio).format('DD-MM-YYYY')}</p>
                          </Row>
                        </Col>

                        <Col xs={12} lg={3} className='mt-2'>
                          <Row>
                            <p>Recuperación Proyectada Inversión:</p>
                            <p className=" fs--4 fw-bold">{fechaProyectadaRetorno}</p>
                          </Row>
                        </Col>

                        <Col xs={12} lg={3} className='mt-2'>
                          <Row>
                            <p>Recuperación Real Inversión:</p>
                            <p className=" fs--4 fw-bold">{fechaRetorno || 'Desconocido'} </p>
                          </Row>
                        </Col>
                        {
                          user.idTypeProfile === TipoPerfilEnum.administrador && (
                          <Col lg={2}>
                            <Button variant="outline-danger" onClick={() => {setShowDetenerModal(!showDetenerModal)}} disabled={!situacionReal}>Detener</Button>
                          </Col>
                          )
                        }
                      </Row>
                    </>           
                </Card.Body>
              </Card>
            </Row>
            <Row>
            <Col lg={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <AdvanceTableWrapper
                      columns={ user.idTypeProfile === TipoPerfilEnum.administrador ? columns : columnsCliente }
                      data={costosRealesActions}
                      sortable
                      pagination
                      perPage={1000}
                    >

                      <div className="d-flex justify-content-between align-items-center mb-2">
                        
                        <h6>Costos Situación Real</h6>
                        { user.idTypeProfile === TipoPerfilEnum.administrador && (
                          <div id="new-document">
                            <IconButton
                              variant="falcon-success"
                              size="sm"
                              icon="plus"
                              transform="shrink-3"
                              className='me-2'
                              onClick={() => setShowItemModal(!showItemModal)}
                              disabled={!situacionReal}
                            >
                              Nuevo ítem
                            </IconButton>
                          </div>
                        ) }
                      </div>
                      <AdvanceTable
                        title='Situación Real'
                        subtitle={`${situacionReal.nombre} - Tabla de Costos Situacion Real`}
                        searchBar={false}
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                          bordered: true,
                          striped: true,
                          className: 'fs--1 mb-0 overflow-hidden'
                        }}
                      />
                    </AdvanceTableWrapper>
                  </Card.Body>
                </Card>
              </Col >
              <Col>
                  <CrmStats 
                    situacionReal={graficarSituacionReal} 
                    situacionAnterior={graficarSituacionAnterior} 
                    propuesta={graficarPropuesta} 
                  />
              </Col >
            </Row>
            <Row>
              <Col className="my-5 text-lg-start">
                <GraficoSituacionReal 
                  situacionReal={graficarSituacionReal} 
                  propuesta={graficarPropuesta} 
                  situacionAnterior={graficarSituacionAnterior} 
                  fechaInicio={fechaInicio}
                  fechaTermino={fechaFinal}
                />
              </Col>
            </Row>
            </>
            )}
          </div>       
        </>
      )
     }

      {/* LOS MODALES ESTARAN DISPONIBLE SOLO CUANDO EL USUARIO NO SEA DE TIPO CLIENTE */}
      { user.idTypeProfile === TipoPerfilEnum.administrador && (
        <>
        
          <div> {/* MODAL PARA AGREGAR UN COSTO */}
            <Modal
                show={showItemModal}
                centered
                onHide={() => setShowItemModal(false)}
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Nuevo ítem
                  </Modal.Title>
                  <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={() => setShowItemModal(false)}
                  />
                </Modal.Header>
                <Modal.Body className="p-0">
                  <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                        <NuevoItem data={situacionReal} fechaInicio={fechaInicio} change={handleChangeNuevoItem} setShowItemModal={setShowItemModal}/>
                    </Card.Body>
                  </Card>
                </Modal.Body>
              </Modal>
          </div>

          <div> {/* MODAL PARA EDITAR UN COSTO */}
            <Modal
              show={showEditCostoModal}
              centered
              onHide={() => setShowEditCostoModal(false)}
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Editar ítem
                </Modal.Title>
                <CloseButton
                  className="btn btn-circle btn-sm transition-base p-0"
                  onClick={() => setShowEditCostoModal(false)}
                />
              </Modal.Header>
              <Modal.Body className="p-0">
                <Card>
                  <Card.Body className="fs--1 fw-normal p-4">
                    <EditarItem data={editarCosto} fechaInicio={fechaInicio} change={handleChangeEditarItem} setShowEditCostoModal={setShowEditCostoModal}  />
                  </Card.Body>
                </Card>
              </Modal.Body>
            </Modal>
          </div>

          <div> {/* MODAL PARA ELIMINAR UN COSTO */}
            <Modal
              show={showDeleteCostoModal}
              onHide={() => setShowDeleteCostoModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Eliminar ítem</Modal.Title>
              </Modal.Header>
              <Modal.Body>¿Está seguro que quiere eliminar el ítem?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowDeleteCostoModal(false)}
                >
                  Cancelar
                </Button>
                <Button variant="danger" onClick={handleDeleteCosto}>
                  Eliminar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div> {/* MODAL PARA DETENER UNA SITUACION REAL */}
            <Modal
                show={showDetenerModal}
                centered
                onHide={() => setShowDetenerModal(false)}
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                  Detener Situación Real
                  </Modal.Title>
                  <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={() => setShowDetenerModal(false)}
                  />
                </Modal.Header>
                <Modal.Body className="p-0">
                  <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                        <DetenerSituacionReal 
                          costosAnteriores={costosAnteriores}
                          data={situacionReal} 
                          fechaInicio={fechaInicio} 
                          fechaRetorno={fechaRetorno} 
                          change={handleChangeDetener} 
                          setShowDetenerModal={setShowDetenerModal}
                        />
                    </Card.Body>
                  </Card>
                </Modal.Body>
              </Modal>
          </div>
        </>
      ) }
    </>
  )
}