import React, { useState, useEffect } from 'react'
import axios from 'utils/axios';

import TreeView from 'components/common/Treeview';

import { Form, Modal, CloseButton, Card, Button, Row, Col } from 'react-bootstrap';
import { formatRut, toastNotification, validatePass, validateRut, verifyResponseUnauthorizedAwait } from 'helpers/utils';
import { ConfiguracionGraficosUser } from './ConfiguracionGraficosUser';
import FalconCardHeader from 'components/common/FalconCardHeader';

const ModalUsuarios = ({ showModalUsuarios, setShowModalUsuarios, idToUpdate = 0, setIdToUpdate, usuarioToUpdate, setUsuarioToUpdate, crearNewUsuario, updateUsuario}) => {

  //! FORMULARIO
  const [ validated, setValidated ] = useState(false);
  const [ corporativo, setCorporativo ] = useState(0);
  const [ perfil, setPerfil ] = useState(0);
  const [ rut, setRut ] = useState('');
  const [ nombre, setNombre ] = useState('');
  const [ apellidoPaterno, setApellidoPaterno ] = useState('');
  const [ apellidoMaterno, setApellidoMaterno ] = useState('');
  const [ cargo, setCargo ] = useState(0);
  const [ telefono, setTelefono ] = useState(0);
  const [ correo, setCorreo ] = useState('');
  const [ contraseña, setContraseña ] = useState('');
  const [ accesosGraficos, setAccesosGraficos] = useState([]);

  //! NECESARIO PARA LA FUNCIONALIDAD DEL FORM
  const [ corporativos, setCorporativos ] = useState([]);
  const [ perfiles, setPerfiles ] = useState([]);
  const [ cargos, setCargos ] = useState([]);
  const [ estructura, setEstructura ] = useState([]);
  const [ seleccionados, setSeleccionados ] = useState([]);

  const [ updatePass, setUpdatePass ] = useState(false);

  const [ treeviewItems, setTreeviewItems ] = useState([{name: '', icon: ''}])


  const seleccionando = ( seleccionados ) => {
    setSeleccionados(seleccionados);
  }

  const setUserDetails = () => {
    if(showModalUsuarios == false) return
    if(idToUpdate > 0){ // Se va a agregar
        setCorporativo(0)
        setPerfil(usuarioToUpdate.profile.id)
        setRut(handleChangeRut(`${usuarioToUpdate.rut}${usuarioToUpdate.dv}`))
        setNombre(usuarioToUpdate.name)
        setApellidoPaterno(usuarioToUpdate.lastName)
        setApellidoMaterno(usuarioToUpdate.mothersLastName)
        setCargo(usuarioToUpdate.job.id)
        setTelefono(usuarioToUpdate.phone)
        setCorreo(usuarioToUpdate.email)
        setContraseña('')
        getAreasByIdUser(usuarioToUpdate.id)
    }
  }

  useEffect(() => {
    ( showModalUsuarios === true ) ? startResetApp() : closeModal();
  }, [showModalUsuarios]);

  useEffect(() => {
    setTreeviewItems(estructura);
  }, [estructura])

  const closeModal = () => {
    setValidated(false);
    setSeleccionados([]);
    setUpdatePass(false)
    setIdToUpdate(0);
    setUsuarioToUpdate({});
    setEstructura([])
    setCorporativo(0)
    setPerfil(0)
    setRut('')
    setNombre('')
    setApellidoPaterno('')
    setApellidoMaterno('')
    setCargo(0)
    setTelefono(0)
    setCorreo('')
    setContraseña('')
    setAccesosGraficos([]);
  };

  const startResetApp = () => {
    Promise.all([ getAllCorporations(), getAllPerfiles(), getAllTypeCargos() ])
    .then(([ allCorporations, allPerfiles, allTypeCargos ]) => {
        setUserDetails();
    })
    .catch(error => {
        console.error(error);
        toastNotification('warning', 'Error al intentar cargar el formulario,  Por favor intentelo nuevamente');
    })

  }

  const handleChangeRut = ( value = '' ) => {
    let rutSinPuntos = value.replace(/\./g, "");
    let rutSinGuion = rutSinPuntos.replace(/-/g, "");
    let rutFormateado = formatRut(rutSinGuion);
    return rutFormateado;
  };


  //! OBTENER LAS AREAS DEL USUARIO A ACTUALIZAR
  const getAreasByIdUser = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/getAreasByIdUser/${id}`;
    try {
        const { data } = await axios.get(url);
        if( verifyResponseUnauthorizedAwait(data, 1) ) return;
        if( data.statusCode == 200 ) setSeleccionados(data.data);
    } catch (error) {
        console.error(error)
        toastNotification('error', 'Ha ocurrido un error al consultar las áreas a cargo de este usuario')
    }
  }

  //! OBTENER TODOS LOS TIPOS DE CARGOS
  const getAllTypeCargos = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/getAllCargos`;
    try {
        const { data } = await axios.get(url);
        if( data.statusCode == 200 ) setCargos(data.data);
    } catch (error) {
        console.error('Ha ocurrido un error al consultar los cargos');
    } 
  }

  //! OBTENER TODOS LOS COORPORATIVOS
  const getAllCorporations = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/getAllCorporations`;
    try {
        const { data } = await axios.get(url);
        if( data.statusCode == 200 ) setCorporativos(data.data);
    } catch (error) {
        console.error('Ha ocurrido un error al los corporativos');
        throw 'Ha ocurrido un error al los corporativos';
    }
  }


  //! OBTENER TODOS LOS TIPOS DE PERFILES
  const getAllPerfiles = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/perfiles/getAllPerfiles`;
    try {
        const { data } = await axios.get(url);
        if( data.statusCode == 200 ) setPerfiles(data.data);
    } catch (error) {
        console.error('Ha ocurrido un error al los corporativos');
    }
  }

  //! OBTENER ESTRUCTURA DEL COORPORATIVO
  const getEstructuraCoorporativoById = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/getEstructuraCorporativoById/${id}`
    try {
      const { data } = await axios.get(url);
      if( verifyResponseUnauthorizedAwait(data, 1) ) return;
      if(data.statusCode == 200) {
        //! Estructura
        const estructura = generateStructure(data.data);
        const newStru = removeEmptyChildren(estructura);
        setEstructura(newStru);
      }
      if(data.statusCode !== 200 ){
        setEstructura([])
      }
    } catch (error) {
      console.error("Algo salio mal al intentar consultar la estructura del corporativo");
      setEstructura([])
      toastNotification('error', 'Algo salió mal al consultar los corporativos')
    }
  }


  //! FUNCION PARA CONSTRUIR LA ESTRUCTURA DEL COORPORATIVO
    function generateStructure( dato ) {
        const { areas, lineas, plantas } = dato;
        const structure = []
      
        for (const planta of plantas) {
          const plantaNode = {
            name: planta.nombre,
            icon: "image",
            type: "planta",
            id: planta.id,
            children: []
          }
      
          const lineasForPlanta = lineas.filter(linea => linea.id_planta === planta.id)
      
          for (const linea of lineasForPlanta) {
            const lineaNode = {
              name: linea.descripcion,
              icon: "image",
              type: "linea",
              id: linea.id,
              children: []
            }
      
            const areasForLinea = areas.filter(area => area.id_linea === linea.id)
      
            for (const area of areasForLinea) {
              lineaNode.children.push({
                name: area.nombre,
                icon: "table",
                type: "area",
                id: area.id
              })
            }
      
            plantaNode.children.push(lineaNode)
          }
      
          structure.push(plantaNode)
        }

        return structure;
    }

    function removeEmptyChildren(obj) {
        for (let i = 0; i < obj.length; i++) {
          if (obj[i].children && obj[i].children.length === 0) {
            delete obj[i].children;
          } else if (obj[i].children) {
            removeEmptyChildren(obj[i].children);
          }
        }
        return obj;
    }


  const handleSubmit = (e) => {
    e.preventDefault();
    
    const form = e.currentTarget;
    if (form.checkValidity() === false || ( rut !== '' && !validateRut(rut) )) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }

    if( perfil == 0 ||  cargo == 0 ) {
        toastNotification('warning', 'Debe seleccionar un perfil y cargo valido')
        return
    }

    let rutFormat = rut.trim();
    rutFormat = rutFormat.replace(/\./g, '');
    rutFormat = rutFormat.replace(/\-/g, '');
    const dv = rutFormat.substr(-1)
    rutFormat = rutFormat.substring(0, rutFormat.length - 1);

    const usuario = { idCoorporativo:corporativo, idPerfil:perfil, rut:rutFormat, dv, nombre, apellidoPaterno, apellidoMaterno, cargo, telefono: telefono.toString(), correo, areas:seleccionados, accesosGraficos: accesosGraficos }

    if( idToUpdate > 0 ){
        //! ACTUALIZAR
        if( updatePass == true ){
            const passValid = validatePass(contraseña);
            if( !passValid ) return
            usuario.contraseña = contraseña;
        }
        usuario.id = idToUpdate;
        updateUsuario(usuario)
    }else{
        //! AGREGAR
        const passValid = validatePass(contraseña);
        if( !passValid ) return
        usuario.contraseña = contraseña;
        crearNewUsuario(usuario);
    }

  }

  return (
    <>
    <Modal
        size='xl'
        show={showModalUsuarios}
        centered
        onHide={() => setShowModalUsuarios(false)}
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                { ( idToUpdate > 0 ) ? ( <p>Editar Usuario</p> ) : ( <p>Nuevo Usuario</p> ) }
            </Modal.Title>
            <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShowModalUsuarios(false)}
            />
        </Modal.Header>
        <Modal.Body className="p-0">
            <Card>
                <Card.Body className="fs-1 fw-normal p-4">

                    <Form className='my-4' noValidate validated={validated} onSubmit={handleSubmit} >
                        <Row className='g-3'>
                            <Col lg={7}>
                                <Card className='p-3'>
                                    <h4 className="mb-4">Datos de Usuario</h4>
                                        <Row>

                                            <Form.Group className="mb-3" controlId="corporativo">
                                                <Form.Label>Coorporativo:</Form.Label>
                                                <Form.Select
                                                    value={corporativo}
                                                    onChange={(e) => {
                                                        setCorporativo(Number(e.target.value))
                                                        getEstructuraCoorporativoById(e.target.value)
                                                    }}
                                                >  
                                                    <option value={0}>Seleccione Opcion</option>
                                                    { corporativos.map( coorp => 
                                                        (<option value={coorp.id}>{coorp.nombre}</option>)
                                                    ) }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor seleccione un país valido
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="perfil">
                                            <Form.Label>Perfil:</Form.Label>
                                                <Form.Select
                                                    value={perfil}
                                                    onChange={(e) => {
                                                        setPerfil(Number(e.target.value))
                                                    }}
                                                >  
                                                    <option value={0} >Seleccione Opcion</option>
                                                    { perfiles.map( perfil => 
                                                        (<option value={perfil.id}>{perfil.name}</option>)
                                                    ) }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor seleccione un perfil valido
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="rut">
                                                <Form.Label>Rut:</Form.Label>
                                                <Form.Control 
                                                    autoComplete="off"
                                                    type="text"
                                                    name="rut"
                                                    maxLength={12}
                                                    onChange={(e) => setRut(handleChangeRut(e.target.value))}
                                                    value={rut}
                                                    isInvalid={ validated && !validateRut(rut) && rut !== '' }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor ingresar un rut valido.
                                                </Form.Control.Feedback>              
                                            </Form.Group>


                                            <Form.Group className="mb-3" controlId="nombre">
                                                <Form.Label>Nombre:</Form.Label>
                                                <Form.Control 
                                                    autoComplete="off"
                                                    type="text"
                                                    name="nombre"
                                                    onChange={(e) => setNombre(e.target.value)}
                                                    value={nombre}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor ingresar un nombre valida.
                                                </Form.Control.Feedback>
                                            </Form.Group>


                                            <Form.Group className="mb-3" controlId="apellidoPaterno">
                                                <Form.Label>Apellido Paterno:</Form.Label>
                                                <Form.Control 
                                                    autoComplete="off"
                                                    type="text"
                                                    name="apellidoPaterno"
                                                    onChange={(e) => setApellidoPaterno(e.target.value)}
                                                    value={apellidoPaterno}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor ingresar un apellido paterno valido.
                                                </Form.Control.Feedback>
                                            </Form.Group>


                                            <Form.Group className="mb-3" controlId="apellidoMaterno">
                                                <Form.Label>Apellido Materno:</Form.Label>
                                                <Form.Control 
                                                    autoComplete="off"
                                                    type="text"
                                                    name="apellidoMaterno"
                                                    onChange={(e) => setApellidoMaterno(e.target.value)}
                                                    value={apellidoMaterno}
                                                    // required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor ingresar un apellido materno valida.
                                                </Form.Control.Feedback>
                                            </Form.Group>


                                            <Form.Group className="mb-3" controlId="perfil">
                                            <Form.Label>Cargo:</Form.Label>
                                                <Form.Select
                                                    min={1}
                                                    value={cargo}
                                                    onChange={(e) => {
                                                        setCargo(Number(e.target.value))
                                                    }}
                                                >  
                                                    <option value={0} >Seleccione Opcion</option>
                                                    { cargos.map( cargo => 
                                                        (<option value={cargo.id}>{cargo.nombre}</option>)
                                                    ) }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor seleccione un perfil valido
                                                </Form.Control.Feedback>
                                            </Form.Group>


                                            <Form.Group className="mb-3" co
                                                    autoComplete="off" ntrolId="telefono">
                                                <Form.Label>Teléfono:</Form.Label>
                                                <Form.Control 
                                                    type="number"
                                                    name="telefono"
                                                    onChange={(e) => e.target.value.length > 9 ? null : setTelefono(e.target.value)}
                                                    value={telefono}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor ingresar un numero de teléfono valido.
                                                </Form.Control.Feedback>
                                            </Form.Group>


                                            <Form.Group className="mb-3" controlId="correo">
                                                <Form.Label>Correo:</Form.Label>
                                                <Form.Control 
                                                    autoComplete="off"
                                                    type="text"
                                                    name="correo"
                                                    onChange={(e) => setCorreo(e.target.value)}
                                                    value={correo}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor ingresar una dirección de correo electronico valido.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            { ( idToUpdate > 0 ) ? (
                                                <Form.Group className="mb-3">
                                                    <Form.Check type="checkbox" label="Actualizar Contraseña?" value={updatePass} onChange={() => setUpdatePass(!updatePass)}  />
                                                </Form.Group> 

                                            ) : (
                                                <Form.Group className="mb-3" controlId="contraseña">
                                                    <Form.Label>Contraseña:</Form.Label>
                                                    <Form.Control 
                                                        autoComplete="off"
                                                        type="text"
                                                        name="contraseña"
                                                        onChange={(e) => setContraseña(e.target.value)}
                                                        value={contraseña}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Por favor ingresar una valida.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            ) }

                                            { updatePass && 
                                                <Form.Group className="mb-3" controlId="contraseña">
                                                    <Form.Label>Contraseña:</Form.Label>
                                                    <Form.Control 
                                                        autoComplete="off"
                                                        type="text"
                                                        name="contraseña"
                                                        onChange={(e) => setContraseña(e.target.value)}
                                                        value={contraseña}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Por favor ingresar una valida.
                                                    </Form.Control.Feedback>
                                                </Form.Group> 
                                            }
                                        </Row>

                                </Card>

                            </Col>
                    
                            <Col lg={5}>
                                <Card>
                                    <FalconCardHeader 
                                        title="Estructura del Corporativo" 
                                        titleTag="h6"
                                        className="py-2"
                                        light
                                    />
                                    <Card.Body className='p-4'>
                                        { corporativo > 0 
                                            ? (
                                                <TreeView 
                                                    data={treeviewItems}
                                                    selection
                                                    setSelectedItems={seleccionando}
                                                    selectedItems={seleccionados}
                                                />
                                            ) 
                                            : <p className='fs--1 text-center fw-bold mt-2'>Corporativo no seleccionado</p>
                                        }
                                    </Card.Body>
                                    
                                </Card>

                                <ConfiguracionGraficosUser 
                                    key={'ConfiguracionGraficosUser'}
                                    titulo='Configuración de Gráficos'
                                    accesosGraficos={accesosGraficos}
                                    setAccesosGraficos={setAccesosGraficos}
                                    idToUpdate={idToUpdate}
                                />
                            </Col>

                            <Form.Group className='text-end'>
                                <Button
                                    variant="secondary"
                                    className="m-2"
                                    onClick={() => setShowModalUsuarios(false)}
                                >
                                    Cancelar
                                </Button>
                                <Button type="submit" variant="success" className="m-2">
                                    Guardar
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>


                </Card.Body>

            </Card>
        </Modal.Body>
        </Modal>
    </>
  )
}

export default ModalUsuarios