import React, { useState, useEffect } from 'react'

import { Card, Row, Col, Modal, Button, Spinner } from 'react-bootstrap'
import axios from 'utils/axios';

import useAuth from 'hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { verifyAccess } from 'helpers';

import { columnsPaises } from '../data.js';
import { toastNotification, verifyResponseUnauthorizedAwait } from 'helpers/utils';

import ModalPaises from "./components/ModalPaises.js"
import BitacoraEntidad from "../components/BitacoraEntidad.js"
import BitacoraData from "../components/BitacoraData.js"
import ModalDesabilitados from "./components/ModalDesabilitados.js"

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import useLoadingContext from 'hooks/useLoadingContext.js';

const PaisesAdmin = () => {
    const navigate = useNavigate();

    const {user} = useAuth();
    const { isLoading, setIsLoading } = useLoadingContext();

    //! Bitacoras
    const [ idEntidad, setIdEntidad ] = useState(17);
    const [ idBitacoraData, setIdBitacoraData ] = useState([]);

    //! general
    const [ paises, setPaises ] = useState([]);
    const [ paisesToTable, setPaisesToTable ] = useState([]);
    const [ readyContent, setReadyContent ] = useState(false);

    //! Modales
    const [ showPaisesModal, setShowPaisesModal ] = useState(false);
    const [ showModalDisabled, setShowModalDisabled ] = useState(false);
    const [ showBitacoraEntidad, setShowBitacoraEntidad ] = useState(false);
    const [ showBitacoraData, setShowBitacoraData ] = useState(false);
    const [ showDeleteModal, setShowDeleteModal ] = useState(false);

    //! To Update
    const [ idToUpdate, setIdToUpdate ] = useState(0);
    const [ idToDelete, setIdToDelete ] = useState(0);
    const [ paisToUpdate, setPaisToUpdate ] = useState(0);


    //? INICIANDO LA APP
    useEffect(() => {
      const hasAccess = verifyAccess(user, 'profilePaisesAdmin', '/administrador/dashboard')
      if( !hasAccess ) navigate("/administrador/dashboard");
      if( hasAccess ) startResetApp();
    }, [])

    const startResetApp = () => {
        setShowPaisesModal(false)
        setShowModalDisabled(false);
        Promise.all([ getAllPaises() ])
          .then(([contries ]) => {
          })
        .catch(error => {
          toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
        })
    }

    useEffect(() => {
        if(paises.length < 0) return
        const paisesToTable = paises.map( pais => {
            let paisToReturn = {}
            paisToReturn.nombre = pais.nombre
            paisToReturn.acciones = [
                <IconButton
                    title="Editar"
                    variant="white"
                    size="sm"
                    icon="edit"
                    style={{ boxShadow: "none", width: "33%" }}
                    onClick={() => {
                        setShowPaisesModal(true)
                        setPaisToUpdate(pais)
                        setIdToUpdate(pais.id)
                    }}
                ></IconButton>,
                <IconButton
                    title="Deshabilitar"
                    variant="white"
                    size="sm"
                    icon="trash-alt"
                    style={{ boxShadow: "none", width: "33%" }}
                    onClick={() => {
                        setIdToDelete(pais.id)
                        setShowDeleteModal(true);
                    }}
                ></IconButton>,
                <IconButton
                    title="Ver Bitácora"
                    variant="white"
                    size="sm"
                    icon="book"
                    style={{ boxShadow: "none", width: "33%" }}
                    onClick={ () => {
                        setIdBitacoraData(pais.id)
                        setShowBitacoraData(true)
                    }}
                ></IconButton>
            ]
            return paisToReturn
        })
        setPaisesToTable(paisesToTable)
    }, [paises])
    
    const getAllPaises = async () => {
      setReadyContent(false);
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/getAllCountries`;
        try {
            const { data } = await axios.get(url);
            if( data.statusCode == 200 ) setPaises(data.data)
            setReadyContent(true);
        } catch (error) {
          setReadyContent(true);
            console.error('Ha ocurrido un error al consultar los paises');
            throw 'Ha ocurrido un error al consultar los paises';
        }
    }
    
    
    const createNewPais = async ( pais ) => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/createNewCountry`;
        try {
          setIsLoading(true);
          const { data } = await axios.post(url, pais)
          setIsLoading(false);
          if( verifyResponseUnauthorizedAwait(data, 2) ) return;
          if( data.statusCode == 201) toastNotification('success', 'País creado correctamente')
          if( data.statusCode !== 201) toastNotification('warning', 'Ocurrió un error al intentar crear el país')
          startResetApp()
      } catch (error) {
          setIsLoading(false);
          console.error('Ha ocurrido un error al crear el país:',error);
          toastNotification('error', 'Ocurrió un error al intentar crear el país')
        }
    }


    const updatePais = async ( pais ) => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/updatePais/${pais.id}`;
        try {
          setIsLoading(true);
          const { data } = await axios.patch(url, pais);
          setIsLoading(false);
          if( verifyResponseUnauthorizedAwait(data, 3) ) return;
          if( data.statusCode == 200 ) toastNotification('success', 'País actualizado correctamente')
          if( data.statusCode !== 200 ) toastNotification('warning', 'Ocurrió un error al intentar actualizar el país')
          startResetApp()
        } catch (error) {
          setIsLoading(false);
          console.error('Ha ocurrido un error al actualizar el país:',error);
          toastNotification('error', 'Ocurrió un error al intentar actualizar el país')
        }
    }


    const enablePaisById = async (id) => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/enableCountry/${id}`;
        try {
          setIsLoading(true);
          const { data } = await axios.patch(url);
          setIsLoading(false);
          if( verifyResponseUnauthorizedAwait(data, 3) ) return;
          if( data.statusCode == 200 ) toastNotification('success', 'País habilitado correctamente')
          if( data.statusCode !== 200 ) toastNotification('warning', 'Ocurrió un error al intentar habilitar el país')
          startResetApp();
        } catch (error) {
          setIsLoading(false);
          console.error('Ha ocurrido un error al habilitar el país:',error);
          toastNotification('error', 'Ocurrió un error al intentar habilitar el país')
        }
    }

    
    const disablePaisById = async () => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/disableContry/${idToDelete}`;
        try {
          setIsLoading(true);
          const { data } = await axios.delete(url);
          setIsLoading(false);
          if( verifyResponseUnauthorizedAwait(data, 4) ) return;
          if( data.statusCode == 200 ) toastNotification('success', 'País deshabilitado correctamente')
          if( data.statusCode !== 200 ) toastNotification('warning', 'Ocurrió un error al intentar deshabilitar el país' )
          startResetApp()
        } catch (error) {
          setIsLoading(false);
          console.error('Ha ocurrido un error al deshabilitar el país:',error);
          toastNotification('error', 'Ocurrió un error al intentar deshabilitar el país')
        }
    }

    return (
        <>
            <Card.Body className="overflow-hidden p-lg-6">
              <Row className="justify-content-between">
                <Col lg={6} className="ps-lg-4 my-5text-lg-start">
                  <h3 className="text-primary">Países</h3>
                </Col>
              </Row>
      
              { !readyContent ? 
                <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>   
              : (
              <Card className="h-md-100">
                <Card.Body>
      
                <AdvanceTableWrapper
                  columns={columnsPaises}
                  data={paisesToTable}
                  sortable
                  pagination
                  perPage={10}
                >
      
                <Row className="flex-end-center mb-3">
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                  <div className='text-center'>
                      <IconButton
                        variant="falcon-success"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                            setShowPaisesModal(true)
                        }}
                      >
                        Nuevo País
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-primary"
                        size="sm"
                        icon="book"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                            setShowBitacoraEntidad(true);
                        }}
                      >
                        Bitácora General
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-warning"
                        size="sm"
                        icon="trash"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                            setShowModalDisabled(true)
                        }}
                      >
                        Deshabilitados
                      </IconButton>
                    </div>
                  </Col>
                </Row>
                
                <AdvanceTable
                  title='Países'
                  subtitle='Tabla de Países'
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                <div className="mt-3">
                  <AdvanceTableFooter
                    rowCount={paises.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                  />
                </div>
              </AdvanceTableWrapper>
                </Card.Body>
              </Card>
              )}
            </Card.Body>

          <div>
            <ModalPaises showPaisesModal={showPaisesModal} setShowPaisesModal={setShowPaisesModal} idToUpdate={idToUpdate} setIdToUpdate={setIdToUpdate} paisToUpdate={paisToUpdate} setPaisToUpdate={setPaisToUpdate} createNewPais={createNewPais} updatePais={updatePais}/>
          </div>

          <div>
            <BitacoraEntidad title={'Bitácora General'} idEntidad={idEntidad} showBitacoraEntidad={showBitacoraEntidad} setShowBitacoraEntidad={setShowBitacoraEntidad}/>
          </div>

          <div>
            <ModalDesabilitados showModalDisabled={showModalDisabled} setShowModalDisabled={setShowModalDisabled} enableData={enablePaisById}/>
          </div>

          <div>
            <BitacoraData title={'Bitacora del Dato'} idEntidad={idEntidad} idBitacoraData={idBitacoraData} showBitacoraData={showBitacoraData} setShowBitacoraData={setShowBitacoraData}/>
          </div>
    
    
          <div>{/* INICIO MODAL PARA ELIMINAR PROPUESTA */}
            <Modal
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Deshabilitar País</Modal.Title>
              </Modal.Header>
              <Modal.Body>¿Está seguro de deshabilitar el País?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  disabled={isLoading}
                  onClick={() => {
                    setShowDeleteModal(false)
                  }}
                  >
                  Cancelar
                </Button>
                <Button 
                  variant="danger"
                  disabled={isLoading}
                  onClick={() => { 
                    disablePaisById()
                    setShowDeleteModal(false)
                  }}
                >
                  Deshabilitar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>{/* FIN MODAL PARA ELIMINAR PROPUESTA */}
    
        </>
    )
}

export default PaisesAdmin