import { Badge, Button, Card, CloseButton, Modal, Row } from "react-bootstrap"

export const DetalleMensaje = ({ mensaje, showDetailMensajeModal, setShowDetailMensajeModal }) => {

    return (
        <>
            { showDetailMensajeModal && (
                <Modal
                    size="lg"
                    show={showDetailMensajeModal}
                    centered
                    onHide={() => setShowDetailMensajeModal(false)}
                >
                    <Modal.Header>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Detalle del Mensaje
                        </Modal.Title>

                        { <div className="ms-4">{ <Badge pill bg='primary'>{ mensaje.type }</Badge> }</div>}

                        <CloseButton
                            className="btn btn-circle btn-sm transition-base p-0"
                            onClick={() => setShowDetailMensajeModal(false)}
                        />
                    </Modal.Header>

                    <Modal.Body className="m-3">
                        
                        <div>
                            <Row className="ml-2">

                                <div className="row text-center">
                                    <p className="col-sm-6"> Usuario: <span className="fw-bold">{`${mensaje.author}`}</span></p>
                                    <p className="col-sm-6"> Fecha: <span className="fw-bold">{`${mensaje.date_message}`}</span></p>
                                </div>

                            </Row>
                            
                            <Row>
                                <Card className="mx-auto py-3">

                                    <div className="row mb-4">
                                        <div className="col-md-3 col-xs-12">
                                            <span className="fw-bold">Asunto:</span>
                                        </div>
                                        <div className="col-md-9 col-xs-12">
                                            { mensaje.issue }
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-3 col-xs-12">
                                            <span className="fw-bold">Mensaje:</span>
                                        </div>
                                        <div className="col-md-9 col-xs-12">
                                            { mensaje.message }
                                        </div>
                                    </div>
                                </Card>
                            </Row>
                        </div>
            

                        { mensaje.mensaje_estado.nombreEstado === 'Cerrado' && (
                            <div className="mt-4" >
                                <Row className="ml-2">

                                    <div className="row text-center">
                                        <p className="col-sm-6"> Resuleto por: <span className="fw-bold">{`${mensaje.usuario_resolucion?.name || ''} ${mensaje.usuario_resolucion.lastName}`}</span></p>
                                        <p className="col-sm-6"> Fecha de Resolución: <span className="fw-bold">{`${mensaje.date_resolucion}`}</span></p>
                                    </div>

                                </Row>
                                
                                <Row>
                                    <Card className="mx-auto py-3">

                                        <div className="row mb-4">
                                            <div className="col-md-4 col-xs-12">
                                                <span className="fw-bold">Mensaje de Resolución:</span>
                                            </div>
                                            <div className="col-md-8 col-xs-12">
                                                { mensaje.descripcion_resolucion }
                                            </div>
                                        </div>

                                    </Card>
                                </Row>
                            </div>
                        ) }

                    </Modal.Body>

                    <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={() => setShowDetailMensajeModal(false)}
                    >
                        Aceptar
                    </Button>
                    </Modal.Footer>
                </Modal>
            ) }
        </>
    )
}
